
/* You can add global styles to this file, and also import other style files */
/* @import url('https://unpkg.com/bootstrap@3.3.7/dist/css/bootstrap.min.css'); */
/* @import url('assets/components/css/style.css'); */

/* @tailwind base; */
@tailwind components;
@tailwind utilities;

@layer utilities {
  /*The code rewrites the tailwind hidden, and it's too generic to easily replace with a different classname so creating a new one*/
  .display-none {
    display: none;
  }

  .container {
    @apply px-6 w-full mx-auto max-w-[1296px];
  }

  .background-image {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
  }

  .fixed-cover {
    @apply fixed left-0 top-0 w-full h-full;
  }

  .fit-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .fill-image {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .h1 {
    @apply font-caslon text-[32px] leading-[37px] lg:text-[61px] lg:leading-[70px];
  }

  .h5 {
    @apply font-big-caslon text-lg lg:text-[25px];
  }

  .link-cta {
    @apply underline hover:no-underline focus:no-underline underline-offset-8 uppercase;
  }

  .unset-button {
    @apply bg-transparent border-0 p-0 hover:bg-transparent focus:bg-transparent hover:opacity-75 focus:opacity-75 m-0;
  }

  /*Buttons*/
  .btn {
    @apply !cursor-pointer h-auto uppercase flex justify-center border border-solid items-center rounded-none disabled:bg-primary/30;
  }

  .button-primary {
    @apply btn bg-primary text-white p-3 no-underline !border-primary uppercase
    hover:bg-white hover:text-primary focus:opacity-75;
  }

  .button-secondary {
    @apply btn bg-white text-primary p-1 lg:p-3 !border-primary no-underline
    hover:bg-primary hover:text-white focus:opacity-75;
  }

  .button-tertiary {
    @apply btn text-blue cursor-pointer underline underline-offset-8 p-3 !border-transparent uppercase !cursor-pointer hover:opacity-50 focus:opacity-50;
  }

  .button-light {
    @apply text-white disabled:bg-white/30;
  }

  .button-primary--light {
    @apply bg-white text-primary !border-white hover:text-white focus:text-white hover:bg-black focus:bg-black;
  }

  .button-secondary--light {
    @apply bg-primary text-white !border-white hover:bg-white hover:text-primary focus:bg-white focus:text-primary;
  }

  .button-large {
    @apply p-3;
  }

  .button-medium {
    @apply py-[7px];
  }

  .button-small {
    @apply px-[3px] p-0 text-sm lg:px-6 min-h-8;
  }
}

@import url('assets/components/css/rcyc-style.css');
@import url('assets/components/css/rcyc-phone-flag-style.css');
@import url('assets/components/css/base.css');
@import url('assets/components/css/n10.brand-local-nav.css');
@import url('assets/components/css/brand_nav_header.css');
@import url('assets/components/css/brand_header.css');
@import url('assets/components/css/brand-menu.css');
@import url('assets/components/css/brand_hero.css');
@import url('assets/components/css/intro-header.css');
@import url('assets/components/css/universal-footer.css');
@import url('assets/components/css/tabs.css');
@import url('assets/components/css/photo-tour.css');

@import url('assets/components/css/custom.css');
@import url('assets/components/css/media_object.css');
@import url('assets/components/css/c19.details.css');
@import url('assets/components/css/n11.pagination.css');
@import url('https://use.typekit.net/qlp0wvt.css');

@font-face {
    font-family:Proxima_Nova;
    src:url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
    }

@font-face {
    font-family:ProximaNovaA-Thin;
    src:url("https://use.typekit.net/af/154cda/00000000000000007735e601/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("woff2"),url("https://use.typekit.net/af/154cda/00000000000000007735e601/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("woff"),url("https://use.typekit.net/af/154cda/00000000000000007735e601/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:100;font-stretch:normal;
    }

@font-face {
    font-family:Proxima_Nova_bold;
    src:url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
    }

@font-face {
    font-family:Proxima_Nova_semi_bold;
    src:url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
    }

body {
    font-family: 'proxima-nova', 'Arial Unicode', sans-serif;
    font-weight: normal;
}
h1 ,h2,h3,h4,h5,h6 , p {
    font-weight: normal;
}

.form_session h2{
    text-align: center;
    padding: 0;
    margin: 0;
    padding-top: 25px;
    font-size: 36px;
    font-family: ProximaNovaA-Thin;
    text-transform: uppercase;
    font-weight: 100;
}
.form_session h3{
    text-align: center;
    padding: 0;
    margin: 0;
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 50px;
    color: #777777;
}
label.center-block {
    font-weight: normal;
    line-height: 40px;
}
input, select {
    background: white;
    border: 1px solid #ffffff;
    height: 40px;
    width: 100%;
    border-radius: 2px;
    color: #1c1c1c;
    text-indent: 0;
    border-bottom: 1px solid #636363;
    font-size: 17px;
    /* text-transform: capitalize; */
    letter-spacing: .5px;
}
input:focus , select:focus {
    border: 1px solid #fff;
    box-shadow: none;
    outline: 0;
    border-bottom: 1px solid #636363;

}
::-webkit-input-placeholder { /* Chrome */
    color: black;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: black;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: black;
    opacity: 1;
  }
  :-moz-placeholder { /* Firefox 4 - 18 */
    color: black;
    opacity: 1;
  }
input[type="radio"]{
    margin-top: 16px;
    margin-right: 10px;
    height: 20px;
    width: 18px;
    padding: 0;
}
input[type="radio"]:last-child {
    margin-left: 20px;
}
.form-group {
    float: left;
    width: 100%;
}
input[type="radio"] + span {
    line-height: 10px;
    display: inline-block;
    height: 15px;
    width: 35px;
    position: relative;
    top: -6px;
}
button {
    text-align: center;
    padding: 8px 30px;
    border: 0;
    border: 1px solid #5d5b5b;
    border-radius: 2px;
    background: #757575;
    color: white;
    text-transform: uppercase;
    margin-top: 30px;
}
button:hover {
    background: #5d5b5b;
}
a {
    color:#3b68b3 ;
/* text-decoration: underline; */
}
/**:focus {outline:none;    text-decoration: underline;}*/
*:focus {
	outline:none;
}
.osano-cm-widget--position_right {
    display: none;
}

[id='ccpacontainer'] {
    display: none;
}
[id='teconsent'] {
    display: none;
}

span.error {
    padding: 10px 0;
    display: inline-block;
    color: #ce4844;
    font-size: 14px;
    letter-spacing: .5px;
    line-height: 14px;
}
.errorWarningSection p, .EWEachOuter p {
    line-height: 20px !important;
    letter-spacing: .30px !important;
}
.backdrop-blur-effect {
	backdrop-filter: blur(8px);
}

/* DRPAL CONTROLLED CLASSES */

    /*drp expands for drupal*/
    .drp-memorable-blue{
        color: #C7DDF3 !important;
    }
    .drp-hyperlink-blue{
        color: #3B68B3 !important;
    }
    .drp-carlton-black{
        color: #1C1C1C !important;
    }
    .drp-grey1{
        color: #F2F2F2 !important;
    }
    .drp-grey2{
        color: #E5E5E5 !important;
    }
    .drp-grey3{
        color: #BFBFBF !important;
    }
    .drp-grey4{
        color: #6f6f6f !important;
    }
    .drp-grey5{
        color: #333333 !important;
    }
    .drp-success{
        color: #468847 !important;
    }
    .drp-error{
        color: #CE4844 !important;
    }
    .drp-warning{
        color: #FF9500 !important;
    }
    .drp-information{
        color: #0565AE !important;
    }
    @media only screen and (max-width: 600px){
        .play-btn{
            margin-top: 24px !important;
        }
    }

    .backdrop-blur-effect {
      backdrop-filter: blur(8px);
    }

/* DRPAL CONTROLLED CLASSES */

.text-content p {
    @apply text-primary;
  }
  
  .text-content--light p {
    @apply text-white;
  }