.n10_A {
  margin-bottom: 60px;
  text-align: left;
  height: 44px;
  overflow: hidden;
  background: transparent;
  border-top: 1px solid #bfbfbf;
  border-bottom: 1px solid #bfbfbf;
  position: relative;
  transition: height 0.5s;
  transition-timing-function: ease-in; }
  @media  (max-width: 42em) {
    .n10_A {
      margin-bottom: 45px; } }
  .n10_A.is-open {
    height: 270px; }
  @media  (min-width: 47.9375em) {
    .n10_A {
      height: 52px;
      overflow: hidden; } }
  .n10_A ul {
    list-style: none;
    display: block;
    vertical-align: top;
    max-width: 1200px;
    margin: 0 auto; }
    @media  (min-width: 47.9375em) {
      .n10_A ul {
        text-align: right;
        padding: 0 8px; } }
  .n10_A li {
    margin: 0;
    display: block; }
    @media  (min-width: 47.9375em) {
      .n10_A li {
        display: inline-block;
        vertical-align: top; }
        .n10_A li:first-child {
          float: left; }
        .n10_A li:last-child a {
          padding-right: 0; } }
    .n10_A li a {
      display: block;
      position: relative;
      letter-spacing: .1em;
      color: #1c1c1c;
      text-transform: uppercase;
      font-size: 13px;
      padding: 14px 8px 10px;
      transition: background 0.3s; }
      @media  (min-width: 47.9375em) {
        .n10_A li a {
          display: inline-block;
          height: 50px;
          padding: 18px 8px; } }
      @media  (min-width: 60em) {
        .n10_A li a {
          padding: 18px 16px; } }
      @media  (min-width: 79.5em) {
        .n10_A li a {
          padding: 18px 20px; } }
      .n10_A li a span {
        display: inline-block;
        padding: 0;
        border-bottom: 1px solid transparent;
        transition-property: border;
        transition-duration: 0.3s; }
      .n10_A li a.current:not([data-option='landing']) span {
        border-color: #6f6f6f; }
        .n10_A li a.current:not([data-option='landing']) span span {
          border: none; }
      .n10_A li a:hover, .n10_A li a:focus {
        text-decoration: none; }
        .n10_A li a:hover span, .n10_A li a:focus span {
          border-color: black; }
          .n10_A li a:hover span span, .n10_A li a:focus span span {
            border: none; }
      @media  (min-width: 47.9375em) {
        .n10_A li a[data-option='landing'] {
          font-family: "ProximaNovaA-Thin", Helvetica, "Arial Unicode", sans-serif;
          font-size: 28px;
          line-height: 1;
          padding-left: 0px;
          padding-top: 13px; }
          .n10_A li a[data-option='landing']:hover span {
            border-color: transparent; } }
  .n10_A .activate-more {
    display: inline-block;
    padding: 14px 11px 14px 17px;
    color: black;
    vertical-align: top;
    width: 44px;
    height: 44px;
    overflow: hidden;
    margin: 0 0 0 8px;
    border-left: 1px solid #bfbfbf;
    transition: border 0.3s; }
    .n10_A .activate-more:hover, .n10_A .activate-more:active {
      text-decoration: none; }
    .n10_A .activate-more [class*="icon"] {
      display: inline-block;
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      -webkit-transform-origin: center center;
              transform-origin: center center; }
    .n10_A .activate-more.is-menu-open {
      border-color: transparent; }
      .n10_A .activate-more.is-menu-open [class*="icon"] {
        margin-top: -1px;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg); }

.brand-local-nav__expand-toggle {
  display: inline;
  position: absolute;
  top: 0;
  right: 7px;
  display:none; /* browse/RCYC-2334*/
 }

.brand-local-nav {
  position: relative; }
  .brand-local-nav:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0; }
/*# sourceMappingURL=n10.brand-local-nav.css.map */
