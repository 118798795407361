footer + footer .footer-engage {
  margin-top: 0; }

.g03, .g04 {
  display: inline-block;
  width: 100%;
  margin-top: 40px; }
  @media  (max-width: 42em) {
    .g03, .g04 {
      margin-top: 25px; } }
  .g04 + .g03, .g05 + .g03, .g04 + .g04, .g05 + .g04 {
    margin-top: 0; }
  /* .flush-footer + .g03, .flush-footer + .g04 {
    margin-top: -100px; } */
  .flush-footer + .n01_G + .g03, .flush-footer + .n01_G + .g04 {
    margin-top: -60px; }

footer ul {
  list-style: none;
  margin: 0; }
footer li {
  margin: 0; }
footer .footer-fat {
  background-color: #c7ddf3; }
  @media  (min-width: 47.9375em) {
    footer .footer-fat {
      padding-top: 60px;
      padding-bottom: 60px; }
      @media  (max-width: 42em) {
        footer .footer-fat {
          padding-top: 45px; } }
      @media  (max-width: 42em) {
        footer .footer-fat {
          padding-bottom: 45px; } } }
  footer .footer-fat ul {
    vertical-align: top; }
    @media  (min-width: 47.9375em) {
      footer .l-container.footer-legal.footer_top_link{
        padding: 20px 10px;
      }
      footer .footer-fat ul {
        display: inline-block;
        width: 49%;
        margin-right: 0%; }
        footer .footer-fat ul:first-child {
          margin: 0;
margin-bottom: 20px; }
        footer .footer-fat ul:nth-child(2n) {
          margin-right: 0; } }
    @media  (min-width: 64em) {
      footer .footer-fat ul {
        width: 23.875%;
        margin-right: 1.5%; }
        footer .footer-fat ul:first-child {
          margin-bottom: 0; }
        footer .footer-fat ul:last-child {
          margin-right: 0; } }
    footer .footer-fat ul ul {
      width: 100%;
      margin: 0;
      border-bottom: none; }
  footer .footer-fat li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
    footer .footer-fat li.last {
      border-bottom: none; }
  footer .footer-fat ul ul li {
    border-bottom: none; }
  footer .footer-fat a.head {
    padding: 13px 10px 6px 0px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0.25em;
    display: inline-block;
    width: calc(100% - 45px);
    vertical-align: top; }
    footer .footer-fat a.head:hover {
      text-decoration: none; }
    @media (min-width: 47.9375em) {
      footer .footer-fat a.head.magazine {
        display: none; } }
  footer .footer-fat a.f-expand-toggle {
    display: inline-block;
    width: 45px;
    text-align: center;
    text-decoration: none;
    font-size: 20px;
    line-height: 1;
    padding: 15px 0 6px 0;
    vertical-align: top;
    transition: all 0.3s; }
    footer .footer-fat a.f-expand-toggle:hover {
      text-decoration: none; }
    footer .footer-fat a.f-expand-toggle [class*="icon"] {
      display: inline-block;
      transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      -webkit-transform-origin: center center;
              transform-origin: center center; }
    footer .footer-fat a.f-expand-toggle.is-menu-open [class*="icon"] {
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
  @media  (min-width: 47.9375em) {
    footer .footer-fat li {
      border: none; }
    footer .footer-fat a.head {
      width: auto;
      padding: 0; }
      footer .footer-fat a.head:hover {
        text-decoration: underline; }
    footer .footer-fat a.expand-toggle {
      display: none; } }
  footer .footer-fat .fat-level-2 {
    display: none; }
    footer .footer-fat .fat-level-2 a {
      display: block;
      margin: 0 0 0.25em;
      padding: 0.25em 0; }
    @media  (min-width: 47.9375em) {
      footer .footer-fat .fat-level-2 {
        display: block; }
        footer .footer-fat .fat-level-2 a {
          display: inline;
          margin: 0;
          padding: 0; } }
    footer .footer-fat .fat-level-2.is-visible {
      display: block; }
  footer .footer-fat a {
    color: #1c1c1c;
    text-decoration: none; }
    footer .footer-fat a:hover {
      text-decoration: underline; }
    footer .footer-fat a.secondary-cta {
      color: #2f538e;
      margin: 0; }
footer .footer-sub {
  background-color: #1c1c1c;
  color: white; }
  footer .footer-sub a {
    text-decoration: none; }
    footer .footer-sub a:hover {
      text-decoration: underline; }
footer .footer-engage {
  padding: 20px 0;
  position: relative; }
  footer .footer-engage a {
    color: white; }
  footer .footer-engage .footer-pressrelease a {
    display: inline-block;
    height: 36px;
    padding: 14px 0;
    font-size: 16px;
    text-transform: uppercase; }
footer .footer-legal {
  position: relative;
}

footer .footer-legal p {
  color: #fff;
  font-size: 13px;
}
  footer .footer-legal .legal-brand, footer .footer-legal .legal-terms {
    display: inline-block; }
    footer .footer-legal .legal-brand li, footer .footer-legal .legal-terms li {
      display: inline-block;
      padding-right: 0.5em;
      margin-right: 0.5em; }
  footer .footer-legal .legal-language {
    display: inline-block; }
    @media  (min-width: 64em) {
      footer .footer-legal .legal-language {
        position: absolute;
        right: 0;
        top: 35px;
        z-index: 33;} }
    footer .footer-legal .legal-language li {
      color: #6f6f6f;
      color: #fff;
     }
footer .footer-social {
  position: relative;}
  footer .footer-social a, footer .footer-social p {
    color: #bfbfbf;
    font-size: 14px; }
footer .signup-form {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 25px; }
  @media  (max-width: 42em) {
    footer .signup-form {
      margin-bottom: 10px; } }
  @media  (min-width: 47.9375em) {
    footer .signup-form {
      position: absolute;
      right: 0;
      top: 20px; } }
  footer .signup-form .signup-form_group {
    display: inline-block;
    vertical-align: top; }
    footer .signup-form .signup-form_group label {
      display: none; }
    footer .signup-form .signup-form_group input {
      height: 36px;
      margin: 0; }
    footer .signup-form .signup-form_group input[type="email"] {
      background-color: #1c1c1c;
      border: 1px solid #6f6f6f;
      color: white;
      font-size: 16px;
      width: 100%; }
    footer .signup-form .signup-form_group [type="submit"] {
      padding: 11px 20px 7px 20px;
      background: #a5c1e2;
      color: black; }
    footer .signup-form .signup-form_group.button_group {
      position: absolute;
      right: 0;
      top: 0;
      padding-left: 25px;
      background-color: transparent;
      /* background-image: radial-gradient(25px 20px, ellipse closest-side, #1c1c1c, transparent 100%);  */
    }
    footer .signup-form .signup-form_group ::-webkit-input-placeholder {
      color: #e5e5e5;
      font-family: "Adobe Caslon Pro", "Times New Roman", serif;
      font-style: italic;
      font-size: 16px; }
    footer .signup-form .signup-form_group :-moz-placeholder {
      /* Firefox 18- */
      color: #e5e5e5;
      font-family: "Adobe Caslon Pro", "Times New Roman", serif;
      font-style: italic;
      font-size: 16px; }
    footer .signup-form .signup-form_group ::-moz-placeholder {
      /* Firefox 19+ */
      color: #e5e5e5;
      font-family: "Adobe Caslon Pro", "Times New Roman", serif;
      font-style: italic;
      font-size: 16px; }
    footer .signup-form .signup-form_group :-ms-input-placeholder {
      color: #e5e5e5;
      font-family: "Adobe Caslon Pro", "Times New Roman", serif;
      font-style: italic;
      font-size: 16px; }

.g03_menu {
  display: inline-block;
  vertical-align: bottom; }

.g03_menu_list {
  position: relative; }

.g03_menu_label {
  position: relative;
  padding-right: 20px; }
  .g03_menu_label:after {
    content: '\e62a';
    font-family: "ritz-icons";
    font-size: 13px;
    position: absolute;
    right: 0;
    top: -2px; }
  .g03_menu-is-open .g03_menu_label:after {
    content: '\e629'; }

.g03_menu_option_group {
  display: none;
  position: absolute;
  bottom: 24px;
  background-color: white;
  padding: 5px;
  right: -5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); }
  .g03_menu-is-open .g03_menu_option_group {
    display: block; }

.g03_menu_option .g03_menu_option-link {
  display: block;
  color: #1c1c1c;
  padding: 5px 25px 5px 20px; }
  @media  (min-width: 47.9375em) {
    .g03_menu_option .g03_menu_option-link {
      padding: 0 15px; } }

.g03_menu_option-is-set .g03_menu_option-link {
  position: relative; }
  .g03_menu_option-is-set .g03_menu_option-link:after {
    content: '\e608';
    font-family: "ritz-icons";
    font-size: 10px;
    position: absolute;
    left: 0;
    top: 5px; }
    @media  (min-width: 47.9375em) {
      .g03_menu_option-is-set .g03_menu_option-link:after {
        top: 0; } }

footer .signup-form {
  margin-bottom: 35px; }
  @media  (max-width: 42em) {
    footer .signup-form {
      margin-bottom: 20px; } }
  footer .signup-form .email_group {
    position: relative;
    width: 100%; }
    footer .signup-form .email_group input[type='email'] {
      transition: all 1s ease;
      position: absolute;
      right: 0;
      max-width: 428px;
      width: 100%; }
  footer .signup-form .button_group {
    position: absolute;
    right: 0; }
    footer .signup-form .button_group .basic-form_input {
      width: 100%; }
    footer .signup-form .button_group input[type='submit'] {
      width: 100px; }

.subscribed-message {
  transition: opacity 1s ease;
  height: 0;
  opacity: 0;
  width: 480px;
  height: 32px;
  line-height: 45px; }

footer .signup-form.is-subscribing input[type='email'] {
  border: solid 1px white; }
footer .signup-form.is-subscribing input[type='submit'] {
  background: white;
  color: #6f6f6f;
  width: 160px; }

footer .signup-form.subscribed input[type='email'] {
  background-color: none !important;
  background: none !important;
  border: solid 1px #bfbfbf; }
footer .signup-form.subscribed input[type='submit'] {
  background: white;
  color: #6f6f6f;
  width: 0px;
  padding: 0; }
/*# sourceMappingURL=g03.universal-footer.css.map */