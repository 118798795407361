.brand-nav-wrapper {
  position: relative;
  text-align: center;
  transition-property: background, box-shadow, top;
  transition-duration: 0.3s;
}

@media (max-width: 890px) {
  .brand-nav-wrapper {
    display: none;
  }
}

.show-takeover .brand-nav-wrapper {
  z-index: 105;
}

.is-fixed .brand-nav-wrapper {
  position: fixed;
  width: 100%;
  z-index: 105;
  top: -100px;
  background: #1c1c1c;
}

.show-takeover .is-fixed .brand-nav-wrapper {
  background: #1c1c1c;
  border-bottom: solid 1px #3c3c3c;
}

.is-pushed-down .brand-nav-wrapper {
  top: 0px;
}

@media (min-width: 47.9375em) {
  .brand-nav-wrapper {
    width: 100%;
    position: fixed;
    top: 0;
    background: white;
    z-index: 105;
    border-bottom: solid 1px transparent;
    transition: box-shadow 0.3s;
  }
  .brand-nav-wrapper.is-stuck {
    /* box-shadow: 0px 1px 5px rgba(28, 28, 28, 0.35); */
    z-index: 105;
  }
  .show-takeover .brand-nav-wrapper.is-stuck {
    background: #1c1c1c;
    border-bottom: solid 1px #3c3c3c;
  }
  .brand-nav-wrapper.is-stuck .brand-nav {
    transition: opacity 200ms ease;
    /* opacity: 0; */
  }
  .show-takeover .brand-nav-wrapper {
    background: #191919;
    z-index: 105;
  }
  .brand-home .brand-nav-wrapper {
    background: transparent;
    box-shadow: none;
  }
}

.brand-logo {
  transition: opacity 200ms ease;
  position: absolute;
  width: 100%;
  text-align: center;
  top: 15px;
  opacity: 0;
}

.brand-logo svg {
  width: 220px;
}

.is-stuck .brand-logo {
  transition: opacity 200ms ease 200ms;
  opacity: 1;
}

.show-takeover .brand-logo svg {
  fill: white;
}

.brand-nav {
  transition: opacity 200ms ease 50ms;
  margin-bottom: 20px;
}

@media (min-width: 47.9375em) {
  .brand-nav {
    margin-bottom: 10px;
  }
}

.show-takeover .brand-nav {
  color: white;
}

.brand-nav__item {
  list-style-type: none;
  display: inline-block;
  margin: 0;
  position: relative;
}

.brand-nav__item-reservations {
  position: absolute;
  top: 13px;
  left: 16px;
}

.brand-nav__item-rewards {
  position: absolute;
  top: 18px;
  right: 16px;
}

@media (min-width: 47.9375em) and (max-width: 54em) {
  .brand-nav__item-rewards {
    max-width: 100px;
  }
}

.brand-nav li:last-child a {
  display: none;
}

@media (min-width: 64em) {
  .brand-nav li:last-child a {
    display: block;
  }
}

.brand-nav__link {
  display: block;
  color: #1c1c1c;
  text-transform: uppercase;
  font-size: 13px;
}

.brand-nav-wrapper--reverse .brand-nav__link {
  color: white;
}

@media (min-width: 60em) {
  .brand-nav__link {
    letter-spacing: .05em;
  }
}

@media (min-width: 79.5em) {
  .brand-nav__link {
    letter-spacing: .1em;
  }
}

.brand-nav__link .link-wrapper {
  transition: border-color 0.3s;
  display: inline-block;
  border-bottom: 1px solid transparent;
}

.brand-nav__link:hover, .brand-nav__link:focus {
  text-decoration: none;
}

.brand-nav__link.active .link-wrapper{
  color: #2C4E86;
  border-color: #2C4E86;
}

.brand-nav__link:hover .link-wrapper,
.brand-nav__link:focus .link-wrapper {
  border-color: #1c1c1c;
}

.show-takeover .brand-nav__link {
  color: white;
}

.show-takeover .brand-nav__link:hover .link-wrapper, .show-takeover .brand-nav__link:focus .link-wrapper {
  border-color: #c7ddf3;
}

.brand-nav-wrapper--reverse .brand-nav__link, .is-fixed .brand-nav__link {
  color: white;
}

.brand-nav-wrapper--reverse .brand-nav__link:hover .link-wrapper, .brand-nav-wrapper--reverse .brand-nav__link:focus .link-wrapper, .is-fixed .brand-nav__link:hover .link-wrapper, .is-fixed .brand-nav__link:focus .link-wrapper {
  border-color: #c7ddf3;
}

.is-opaque .brand-nav__link {
  color: #1c1c1c;
}

.is-opaque .brand-nav__link:hover .link-wrapper, .is-opaque .brand-nav__link:focus .link-wrapper {
  border-color: #1c1c1c;
}

.show-takeover .is-opaque .brand-nav__link {
  color: white;
}

.brand-nav__link--reservations {
  border: solid 1px rgba(28, 28, 28, 0.8);
  padding-bottom: 8px;
  padding-top: 6px;
  height: 30px;
  min-width: 110px;
  transition: all 0.3s;
}

.brand-nav-wrapper--reverse .brand-nav__link--reservations, .is-fixed .brand-nav__link--reservations {
  border-color: rgba(255, 255, 255, 0.8);
}

.is-opaque .brand-nav__link--reservations {
  border-color: #1c1c1c;
}

.show-takeover .is-opaque .brand-nav__link--reservations {
  color: #1c1c1c;
}

@media (min-width: 79.5em) {
  .brand-nav__link--reservations {
    width: 140px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 890px)
{
  #rcyc-page-header .section-1 .signinJoin
  {
    float:none !important;
  }
}
.brand-nav__link--reservations:hover, .brand-nav__link--reservations:focus {
  background: white;
  color: #1c1c1c;
  border-color: #1c1c1c;
}

body.show-takeover .brand-nav__link--reservations {
  border-color: rgba(255, 255, 255, 0.8);
  background: white;
  color: #000000;
}

body.show-takeover .brand-nav__link--reservations:hover, body.show-takeover .brand-nav__link--reservations:focus, body.show-takeover .brand-nav__link--reservations:active {
  counter-increment: #000000;
}

.brand-nav__link--rewards {
  opacity: .7;
  transition: opacity 0.3s;
  font-size: 13px;
  padding: 1px 8px 0 0;
  display: inline-block;
  height: 16px;
  overflow: hidden;
  text-transform: capitalize;
}

.brand-nav__link--rewards:last-child {
  border-left: 1px solid #1c1c1c;
  padding: 1px 0px 0px 8px;
}

.brand-nav-wrapper--reverse .brand-nav__link--rewards:last-child {
  border-color: white;
}

.brand-nav__link--rewards:hover, .brand-nav__link--rewards:focus {
  opacity: 1;
}


/*# sourceMappingURL=n09.brand-nav.css.map */