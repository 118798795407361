.c07_object {
  margin-bottom: 60px; }
  @media  (max-width: 42em) {
    .c07_object {
      margin-bottom: 45px; } }
  @media  (min-width: 30em) {
    .c07_object {
      overflow: hidden; } }

.c07_picture {
  margin-bottom: 35px; }
  @media  (max-width: 42em) {
    .c07_picture {
      margin-bottom: 20px; } }
  @media  (min-width: 30em) {
    .c07_picture {
      display: block;
      float: left;
      margin-right: 20px;
      margin-bottom: 0; } }

.c07_img {
  width: 100%;
  display: block; }
  @media  (min-width: 30em) {
    .c07_img {
      display: block;
      float: left;
      margin-right: 10px;
      max-width: 170px; } }

.c07_caption {
  font-style: italic;
  font-family: "Big Caslon", Palatino, "Times New Roman", serif;
  letter-spacing: 0.05em;
  font-size: 12px; }

.c07_secondary-cta {
  margin-top: -10px; }

@media  (min-width: 30em) {
  .c07_body {
    overflow: hidden;
    font-size: 14px; } }

@media  (min-width: 35em) {
  .c07_title_table {
    display: table;
    width: 100%; } }

.c07_title_cell h4 a {
  color: black; }
@media  (min-width: 35em) {
  .c07_title_cell {
    display: table-cell;
    vertical-align: top; }
    .c07_title_cell.c07_title_cell-title {
      width: 75%;
      padding-right: 5px; }
    .c07_title_cell.c07_title_cell-cta {
      width: 25%;
      padding-left: 5px; }
    .c07_title_cell:only-child {
      padding: 0; } }
.c07_title_cell .primary-cta {
  margin-left: 0;
  margin-right: 0; }
@media  (min-width: 60em) {
  .c07_title_cell.c07_title_cell-cta p:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0; }
  .c07_title_cell .primary-cta {
    float: right; } }

.feature-block__image {
  margin-bottom: 35px;
  width: 100%;
  display: block; }
  @media  (max-width: 42em) {
    .feature-block__image {
      margin-bottom: 20px; } }
.feature-block__headline {
  text-transform: uppercase;
  letter-spacing: .05em;
  font-size: 18px; }
  .feature-block__headline a {
    color: black; }

.c07_B h3 {
  text-transform: uppercase; }
  .c07_B h3 a {
    color: black; }
.c07_B .media-object__duration {
  color: #777;
  line-height: 1;
  font-size: 14px; }
.c07_B .media-object__price {
  margin-bottom: 0;
  font-size: 18px; }
  .c07_B .media-object__price span {
    display: block; }
@media  (min-width: 47.9375em) {
  .c07_B {
    max-width: 800px; }
    .c07_B img {
      max-width: 300px; }
    .c07_B p {
      font-size: 16px; }
    .c07_B ul + .media-object__price {
      margin-top: -20px; } }

.c07_media-object {
  margin-bottom: 60px; }
  @media  (max-width: 42em) {
    .c07_media-object {
      margin-bottom: 45px; } }

.c07_D {
  margin-bottom: 0; }
  .c07_D .feature-block {
    margin-bottom: 60px; }
    @media  (max-width: 42em) {
      .c07_D .feature-block {
        margin-bottom: 45px; } }
    .c07_D .feature-block > *:last-child {
      margin-bottom: 0; }

.c07_E {
  margin-bottom: 0; }
  .c07_E .feature-block {
    margin-bottom: 60px; }
    @media  (max-width: 42em) {
      .c07_E .feature-block {
        margin-bottom: 45px; } }
    .c07_E .feature-block > *:last-child {
      margin-bottom: 0; }

.c07_F {
  margin-bottom: 0;
  position: relative; }
  @media  (min-width: 47.9375em) {
    .c07_F .feature-blocks {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
      clear: both;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row; } }
  .c07_F .feature-block {
    overflow: hidden;
    clear: both; }
    @media  (min-width: 47.9375em) {
      .c07_F .feature-block {
        width: calc(33.3333% - 0.67em);
        margin-right: 1em; }
        .c07_F .feature-block:nth-of-type(2n) {
          margin-right: auto; }
        .c07_F .feature-block:nth-of-type(3n) {
          margin-right: 0; } }
    @media  (min-width: 35em) {
      .c07_F .feature-block__image-container {
        width: 33%;
        float: left;
        clear: left; } }
    @media  (min-width: 47.9375em) {
      .c07_F .feature-block__image-container {
        width: 100%;
        float: none; } }
    .c07_F .feature-block__body-container {
      margin-bottom: 60px; }
      @media  (max-width: 42em) {
        .c07_F .feature-block__body-container {
          margin-bottom: 45px; } }
      @media  (min-width: 35em) {
        .c07_F .feature-block__body-container {
          width: 67%;
          float: left;
          padding-left: 10px; } }
      @media  (min-width: 47.9375em) {
        .c07_F .feature-block__body-container {
          width: 100%;
          float: none;
          padding-left: 0px; } }
    @media  (min-width: 60em) {
      .c07_F .feature-block .media-object__headline {
        font-family: "Adobe Caslon Pro", "Times New Roman", serif;
        font-size: 28px; } }
  .showcase .c07_F {
    padding: 0; }

.media-object__headline {
  font-family: "Proxima Nova", Helvetica, "Arial Unicode", sans-serif;
  font-weight: 400;
  text-transform: uppercase; }
  .media-object__headline a {
    color: #1c1c1c; }
  .media-object__headline--small {
    font-size: 18px; }
  .media-object__headline--large {
    font-size: 18px;
    letter-spacing: .05em; }
/*# sourceMappingURL=c07.media-object.css.map */