.tabs-wrapper {
  margin-bottom: 60px;
  padding-top: 20px;
  border-top: solid 1px #bfbfbf; }
  @media  (max-width: 42em) {
    .tabs-wrapper {
      margin-bottom: 45px; } }
  @media  (min-width: 47.9375em) {
    .tabs-wrapper {
      padding-top: 0;
      border-top: 0;
      border-bottom: solid 1px #bfbfbf; } }

.tabs {
  list-style-type: none;
  display: inline; }

.tab {
  margin-left: 0;
  display: inline;
  margin-bottom: 0;
  margin-right: -1px; }
  .tab--alt {
    float: right; }

.tab-link {
  display: inline-block;
  height: 40px;
  padding: 10px 20px 6px 20px;
  margin-bottom: -1px;
  border-top: solid 1px transparent;
  border-left: solid 1px transparent;
  border-right: solid 1px transparent;
  text-decoration: none;
  text-transform: uppercase;
  color: #1c1c1c;
  transition: background 0.3s; }
  @media  (min-width: 60em) {
    .tab-link {
      padding-left: 30px;
      padding-right: 30px; } }
  .tab-link:hover, .tab-link:focus {
    border-top: solid 1px #bfbfbf;
    border-left: solid 1px #bfbfbf;
    border-right: solid 1px #bfbfbf;
    text-decoration: none;
    background: #c7ddf3;
    border-color: #bfbfbf; }
  .tab-link.tab-link--alt {
    background: #1c1c1c;
    border-color: #1c1c1c;
    color: white; }
    .tab-link.tab-link--alt:hover, .tab-link.tab-link--alt:focus {
      color: white;
      background: #363636;
      border-color: #363636; }
  .tab-link.tab-link--secondary {
    background: #e5e5e5;
    border-top: solid 1px #bfbfbf;
    border-left: solid 1px #bfbfbf;
    border-right: solid 1px #bfbfbf;
    border-color: #bfbfbf; }
    .tab-link.tab-link--secondary:hover, .tab-link.tab-link--secondary:focus {
      background: #cccccc;
      border-color: #cccccc; }
  .tab-link.is-active {
    position: relative;
    height: 41px;
    border-top: solid 1px #bfbfbf;
    border-left: solid 1px #bfbfbf;
    border-right: solid 1px #bfbfbf;
    background: white;
    color: #1c1c1c;
    font-weight: 400; }
    .tab-link.is-active:hover, .tab-link.is-active:focus {
      cursor: default;
      color: #1c1c1c;
      background: white;
      border-color: #bfbfbf; }
    .tab-link.is-active:before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: #2f538e; }

.tab-select-wrapper .tab-select {
  padding: 5px;
  height: 34px;
  width: 100%;
  background: transparent;
  border-radius: 0;
  border: 0;
  line-height: 1.1;
  border-bottom: solid 1px black;
  font-size: 21px;
  font-family: "Adobe Caslon Pro", "Times New Roman", serif;
  font-style: italic;
  -webkit-appearance: none;
  -moz-appearance: none; }
.tab-select-wrapper optgroup {
  font-size: 14px;
  padding-left: 10px; }
.tab-select-wrapper option {
  font-size: 14px;
  padding-left: 10px; }
.tab-select-wrapper [class*="icon"] {
  margin-left: -45px;
  position: relative;
  top: 5px;
  padding: 3px 0 3px 20px;
  z-index: -1;
  background: white;
  color: #2f538e;
  font-size: 20px; }

.tab-pane {
  display: none; }
  .tab-pane.is-visible {
    display: block; }

@media  (min-width: 47.9375em) {
  .tab-intro {
    width: 66.1016949153%;
    float: left;
    margin-right: 1.6949152542%; } }

.tab-pane_floorplan {
  text-align: center; }
/*# sourceMappingURL=c04.tabs.css.map */