.n11_list {
    margin-bottom: 60px;
    display: table;
    text-align: center; }
    @media  (max-width: 42em) {
      .n11_list {
        margin-bottom: 45px; } }
  
  .n11_item {
    display: table-cell;
    list-style: none;
    padding: 5px 10px;
    width: auto;
    color: #6f6f6f; }
    @media  (min-width: 47.9375em) {
      .n11_item {
        padding: 5px; } }
    @media  (min-width: 79.5em) {
      .n11_item {
        padding: 5px 10px; } }
    .n11_item:not(.n11_item-critical) {
      display: none; }
      @media  (min-width: 35em) {
        .n11_item:not(.n11_item-critical) {
          display: table-cell; } }
  
  .n11_item-critical {
    display: table-cell; }
  
  .n11_item-previous {
    text-align: left; }
    @media  (min-width: 35em) {
      .n11_item-previous {
        padding-right: 20px;
        padding-left: 0; } }
  
  .n11_item-next {
    text-align: right; }
    @media  (min-width: 35em) {
      .n11_item-next {
        padding-left: 20px;
        padding-right: 0; } }
  
  .n11_link-label {
    display: none; }
    @media  (min-width: 35em) {
      .n11_link-label {
        display: inline; } }
  
  .n11_link-previous {
    position: relative;
    padding-left: 20px; }
    .n11_link-previous:before {
      top: -4px;
      left: 0;
      position: absolute;
      content: '\e601';
      font-family: "ritz-icons"; }
  
  .n11_link-next {
    position: relative;
    padding-right: 20px; }
    .n11_link-next:after {
      top: -4px;
      right: 0;
      position: absolute;
      content: '\e600';
      font-family: "ritz-icons";
    }
    
  /*# sourceMappingURL=n11.pagination.css.map */