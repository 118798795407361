.brand-menu-wrapper {
  position: absolute;
  top: 37px;
  left: -320px;
  max-width: 320px;
  width: 100%;
  height: calc(100% - 37px);
  padding: 24px;
  background: white;
  color: #1c1c1c;
  transition: left 0.3s;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition-timing-function: cubic-bezier 0.7, 0, 0.3, 1; }
  .brand-secondary .brand-menu-wrapper, .brand-home .brand-menu-wrapper {
    top: 45px;
    height: calc(100% - 45px); }
  .show-menu .brand-menu-wrapper {
    z-index: 104;
    right: 0;
    left: auto;
    position: fixed; }
    .show-menu .brand-menu-wrapper .brand-menu {
      overflow: auto; }

@media  (min-width: 47.9375em) {
  body.show-menu {
    padding-top: 37px; } }

.shadow {
  position: fixed;
  top: 37px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(28, 28, 28, 0.9);
  z-index: -1000;
  opacity: 0;
  height: 0;
  transition-property: opacity;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier 0.7, 0, 0.3, 1; }
  .show-menu .shadow {
    display: block;
    opacity: 1;
    height: 100%;
    z-index: 99; }

.content-wrapper {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition-timing-function: cubic-bezier 0.7, 0, 0.3, 1; }
  .show-menu .content-wrapper {
    -webkit-transform: translate3d(320px, 0, 0);
            transform: translate3d(320px, 0, 0); }

.brand-menu {
  list-style: none; }
  .brand-menu a {
    color: #1c1c1c;
    text-decoration: none;
    display: inline-block;}
  .brand-menu li {
    margin-left: 0;
  }
  .brand-menu li:last-of-type {
    border: 0;
  }
  .brand-menu .brand-menu__link {
    width: calc(100% - 45px);
    padding: 13px 10px 7px; }
  .brand-menu .expand-toggle {
    float: right;
    width: 45px;
    text-align: center;
    text-decoration: none;
    font-size: 20px;
    line-height: 1;
    padding-bottom: 8px;
    transition: all 0.3s; }
    .brand-menu .expand-toggle [class*="icon"] {
      display: inline-block;
      transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      -webkit-transform-origin: center center;
              transform-origin: center center; }
    .brand-menu .expand-toggle.is-expanded [class*="icon"] {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .brand-menu .menu-level-2 {
    max-height: 0;
    overflow: hidden;
    position: static;
    margin: 0;
    padding: 0;
    width: 100%;
    transition: max-height 0.3s;
    transition-timing-function: cubic-bezier 0.7, 0, 0.3, 1; }
    .brand-menu .menu-level-2.is-visible {
      max-height: 1000px; }
    .brand-menu .menu-level-2 .brand-menu__link {
      width: 100%; }
    .brand-menu .menu-level-2 li {
      border: none; }
      .brand-menu .menu-level-2 li:hover, .brand-menu .menu-level-2 li:focus {
        background: #c7ddf3; }
      .brand-menu .menu-level-2 li a {
        padding-left: 20px;
        font-size: 14px; }
  .brand-menu .reservations-li {
    border: 0;
    margin: 0 8px; }
  .brand-menu .reservations-link {
    display: block;
    margin: 0;
    padding-bottom: 6px; }

.icon-arrow-down {
  color: #bfbfbf;
  position: relative;
  top: 1px; }
  li:hover .icon-arrow-down, .is-expanded .icon-arrow-down {
    color: black; }
/*# sourceMappingURL=n06.brand-menu.css.map */