.form_section {
  background-color: #1c1c1c;
    color: white;
    padding: 30px 0;
}
.slider_data_list{
  height: auto;
  max-height:100%;
}
.form_section_dark .basic-form_input.basic-form_select{
  width: 100%;
  border: 1px solid #c7ddf3;
  background-color: transparent;
  color: white;
  height: 32px
}
.form_section_dark  .secondary {
  width: 100%;
  height: 32px;
  padding: 5px 9px 9px 9px;
  font-size: 15px;
  margin:0;/*To avoild find a criuse issue mentioned in RCYC-1696*/
}
.form_section_dark .basic-form_input.basic-form_select option{
  color: #1c1c1c;
  padding: 5px 0;
}
.headline-block h4 {
  margin-bottom: 0;
}
.image_content_section img{
  margin-bottom: 35px;
width: 100%;
display: block;

}
.image_content_section a{
  color: #1c1c1c;
  text-decoration: underline;
}
.image_content_section .secondary-cta:after {
  color: #1c1c1c;
}
.image_content_section h3 {
    letter-spacing: .05em;
    font-family: "Proxima Nova", Helvetica, "Arial Unicode", sans-serif;
    font-size: 16px;
    font-weight: normal;
}
.footer-legal.footer_top_link {
  border-top: 0;
}
.call_to_action{
  text-align: center;
}
.footer_top_link ul > li > a {
  color: white;
}
.footer_top_link ul > li > ul li a {
  color: #bfbfbf;
}
.search_xs.brand-header__rewards-link.h-ML {
  right: 40px;
}
body.show-menu .brand-lockup__logo {
  opacity: 0;
}
.brand-logo.logo-id-1 {
  top: 12px;
}
.brand-logo.logo-id-1 svg {
    height: 30px;
    width: 375px;
}
.form_btn_main {
  position: relative;
}
.form_more_option {
    position: absolute;
    width: 100%;
    text-align: right;
}
.form_more_option .g03_menu {
    padding: 0;
    margin: 0;
}
.form_more_option .g03_menu_option_group {
    z-index: 2;
    bottom: -10px;
  }
.form_more_option .g03_menu_option_group li {
    padding: 0;
    list-style: none;
    margin: 0;
}
.form_more_option .g03_menu {
    padding: 0;
    margin: 0;
    width: 100%;
}
.form_more_option .g03_menu li a {
  text-transform: uppercase;
  color: #a5c1e2;
  text-transform: uppercase;
  font-size: 12px;
}
.form_more_option .g03_menu_label:after {
  top: -4px;
}
.form_more_option .g03_menu_option_group .g03_menu_option a {
  color: #1c1c1c;
}
.brand-logo.logo-id-1 {
    top: 52px;
    background: white;
    border-top: 1px solid #bfbfbf;
    padding: 8px;
    box-shadow:-1px 3px 5px rgba(28, 28, 28, 0.23);
    z-index: 102;
    position: absolute;
}
.brand-nav__link--reservations:hover {
  background: #1c1c1c;
  color: white;
}
/* .l-container.footer_bottom {
  padding: 0;
} */

.p02_A_01.p02_photo-tour.p02_A {
  height: 465px;
}
.p02_photo_row_wrapper > .p02_photo_row > .p02_photo  {
      height: 465px;
}
.p02_photo_row_wrapper {
    width: 100%;
    padding: 0 0;
    position: relative;
    max-height: 465px;
}

.tab-pane > .l-container.row {
  padding: 0;
}
.top_wraper_menu h4{
  margin-bottom: 0;
}
.top_wraper_menu span {
  display: inline-block;
      float: right;
      text-transform: uppercase;
      padding: 0 15px;
      font-size: 13px;
      line-height: 18px;
}
.top_wraper_menu span:last-child {
  padding-right: 0;
}
.top_wraper_menu{
    padding: 20px 0;
    border-color: #bfbfbf;
    border: 1px solid #bfbfbf;
    margin: 0;
    border-width: 1px 0;
}
.top_wraper_menu h4{
  line-height: 20px;
  display: inline-block;
  height: 20px;
  font-size: 20px;
}
.p02_image_wrapper p{
  position: absolute;
    bottom: 30px;
    color: white;
    letter-spacing: .05em;
    font-family: "Big Caslon", Palatino, "Times New Roman", serif;
    left: 0px;
    font-size: 36px;
}
.l-container-wrapper.slider_text {
  bottom: 0;
    position: absolute;
    width: 100%;
}
/* .l-container.footer_bottom {
  padding: 0;
} */


.l-container h2{
  font-family: "Adobe Caslon Pro", "Times New Roman", serif;
font-size: 26px;
text-transform: uppercase;
line-height: 1.3;
text-align: center;
margin-top: 46.8px;
}

ul.tab_module_4 li.tab a.tab-link.is-active{
background: white;
}

ul.tab_module_4 li.tab a.tab-link{
background: #bfbfbf;
}
ul.tab_module_4 li.tab a.tab-link.pdf{
background: white;
}
.diningBookingContentInfo  p{
  margin-bottom: 0px!important;
}
.alert.alert-success p
{
  color: #468847;
  padding: 0px 0px 0px 5px;
  margin-bottom: 0;
}
.alert.alert-warning.cancelErrorClass p
{
	color: #468847;
  padding: 0px 0px 0px 5px;
  margin-bottom: 0;

}
