.content-wrapper {
  overflow-x: hidden; }
  /* @media  (min-width: 79.5em) {
    .content-wrapper {
      overflow: auto; } } */

.p02_A {
  margin-top: -20px;
  margin-bottom: -1px; }
  @media  (min-width: 47.9375em) {
    .p02_A {
      margin-top: -40px; } }
  /* @media  (min-width: 79.5em) {
    .p02_A {
      min-height: 675px; } } */

.p02-body-is-fixed {
  position: fixed;
  overflow: hidden; }

.p02_photo_row_wrapper {
  width: 100%;
  padding: 28% 0;
  position: relative; }
  /* @media  (min-width: 79.5em) {
    .p02_photo_row_wrapper {
      padding: 0;
      overflow: hidden; } } */
  .p02_photo_row_wrapper.p02_photo_row_wrapper-is-lightbox {
    padding: 0;
    position: fixed;
    width: 100%;
    top: 0;
    bottom: 0;
    z-index: 120;
    background: white; }

/* @media  (min-width: 79.5em) {
  .p02_photo_row {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 1200px;
    height: 675px; } } */
.p02_photo_row:hover .p02_photo_nav:after {
  opacity: 0.4; }
.p02_photo_row:hover .p02_photo_nav:hover:after {
  opacity: 0.8; }
.p02_photo_row_wrapper-is-lightbox .p02_photo_row {
  position: relative;
  width: 100%;
  height: calc(100% - 105px); }

.p02_photo {
  position: absolute;
  left: calc(100% * 5);
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  overflow: hidden;
  text-align: center;
  width: 100%;
  height: 100%;
  border: none;
  top: -1px; }
  /* @media  (min-width: 79.5em) {
    .p02_photo {
      width: 1200px;
      height: 675px;
      left: 6000px;
      border-left: 3px solid white;
      border-right: 3px solid white; } } */
  .p02_photo_row_wrapper-is-lightbox .p02_photo {
    width: 100%;
    height: 100%;
    border: none; }

.p02_photo-is-active {
  opacity: 1;
  left: 0;
  z-index: 4;
  background-color: #1c1c1c;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .p02_photo-is-active:hover {
    cursor: -webkit-zoom-in;
    cursor: zoom-in; }
  .p02_photo_row_wrapper-is-lightbox .p02_photo-is-active {
    background-color: white;
    cursor: default; }

.p02_image_wrapper {
  position: relative;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 100%; }
  /* @media  (min-width: 79.5em) {
    .p02_image_wrapper {
      height: 675px;
      width: auto; } } */
  .p02_photo_row_wrapper-is-lightbox .p02_image_wrapper {
    height: 100%;
    width: 100%; }

.p02_image {
  display: block;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  max-width: none;
  width: 100%; }
  /* @media  (min-width: 79.5em) {
    .p02_image {
      margin-left: auto;
      margin-right: auto;
      max-height: 675px;
      max-width: 1200px;
      width: auto; } } */
  .p02_photo_row_wrapper-is-lightbox .p02_image {
    max-height: none;
    max-width: none;
    width: 100%; }

.p02_photo-is-left {
  opacity: 0.5;
  left: -100%;
  z-index: 2; }
  .p02_photo-is-left.p02_photo-is-going-left {
    left: -200%; }

.p02_photo-is-lefter {
  left: -200%;
  z-index: 2; }

.p02_photo-is-right {
  opacity: 0.5;
  left: 100%;
  z-index: 3; }
  .p02_photo-is-right.p02_photo-is-going-right {
    left: 22%; }

.p02_photo-is-righter {
  left: 200%;
  z-index: 3; }

.p02_photo-caption {
  display: table;
  color: white;
  height: 40px;
  width: 100%;
  background: rgba(28, 28, 28, 0.4);
  text-align: left;
  bottom: 0;
  position: absolute;
  opacity: 1;
  transition: opacity 0.75s;
  font-size: 12px; }
  .p02_photo-caption-is-active .p02_photo-is-active .p02_photo-caption {
    opacity: 1; }
  @media  (min-width: 30em) {
    .p02_photo-caption {
      height: 60px; } }
  @media  (min-width: 47.9375em) {
    .p02_photo-caption {
      font-size: initial;
      padding-left: 95px;
      opacity: 0; }
      .p02_photo-caption-is-active .p02_photo-is-active .p02_photo-caption {
        opacity: 1; } }

.p02_photo-caption-cell {
  display: table-cell;
  vertical-align: middle;
  padding: 20px 5px 12px 5px; }

.p02_photo_nav {
  height: 100%;
  position: absolute;
  z-index: 20;
  color: #1c1c1c;
  width: 45px;
  top: 0; }
  @media  (min-width: 47.9375em) {
    .p02_photo_nav {
      width: 65px; } }
  /* @media  (min-width: 79.5em) {
    .p02_photo_nav {
      width: 1200px;
      height: 675px; } } */
  .p02_photo_nav:after {
    font-family: "ritz-icons";
    font-size: 45px;
    display: block;
    height: 45px;
    width: 45px;
    position: absolute;
    top: calc(50% - 23px);
    opacity: 0;
    text-shadow: 0 1px white; }
    @media  (min-width: 47.9375em) {
      .p02_photo_nav:after {
        font-size: 65px;
        height: 65px;
        width: 65px;
        top: calc(50% - 32px); } }
  .p02_photo_row_wrapper-is-lightbox .p02_photo_nav {
    height: 100%; }

.p02_photo_nav-right {
  right: 0; }
  /* @media  (min-width: 79.5em) {
    .p02_photo_nav-right {
      left: 100%;
      right: auto; } } */
  .p02_photo_nav-right:after {
    content: url(../images/arrow_right-2color.svg);
    left: 6px; }
    @media  (min-width: 47.9375em) {
      .p02_photo_nav-right:after {
        left: 2px; } }
    /* @media  (min-width: 79.5em) {
      .p02_photo_nav-right:after {
        left: 20px; } } */
  .p02_photo_nav-right:before {
    content: '';
    width: 100%;
    height: 100%;
    display: block; }
    /* @media  (min-width: 79.5em) {
      .p02_photo_nav-right:before {
        background: linear-gradient(to left, #ffffff 54%, rgba(255, 255, 255, 0.85) 61%, rgba(255, 255, 255, 0) 100%); } } */
  .p02_photo_row_wrapper-is-lightbox .p02_photo_nav-right {
    right: 0;
    left: auto; }
    @media  (min-width: 47.9375em) {
      .p02_photo_row_wrapper-is-lightbox .p02_photo_nav-right {
        width: 65px; } }
    .p02_photo_row_wrapper-is-lightbox .p02_photo_nav-right:after {
      opacity: 0.4;
      left: 6px; }
      @media  (min-width: 47.9375em) {
        .p02_photo_row_wrapper-is-lightbox .p02_photo_nav-right:after {
          left: 2px; } }
    .p02_photo_row_wrapper-is-lightbox .p02_photo_nav-right:before {
      background: transparent; }
    .p02_photo_row_wrapper-is-lightbox .p02_photo_nav-right:hover:after {
      opacity: 0.8; }

/* @media  (min-width: 79.5em) {
  .p02_photo_nav-left {
    left: -100%; } } */
.p02_photo_nav-left:after {
  content: url(../images/arrow_left-2color.svg);
  right: 9px; }
  @media  (min-width: 47.9375em) {
    .p02_photo_nav-left:after {
      right: 2px; } }
  /* @media  (min-width: 79.5em) {
    .p02_photo_nav-left:after {
      right: 20px; } } */
.p02_photo_nav-left:before {
  content: '';
  width: 100%;
  height: 100%;
  display: block; }
  /* @media  (min-width: 79.5em) {
    .p02_photo_nav-left:before {
      background: linear-gradient(to right, #ffffff 54%, rgba(255, 255, 255, 0.85) 61%, rgba(255, 255, 255, 0) 100%); } } */
.p02_photo_row_wrapper-is-lightbox .p02_photo_nav-left {
  left: 0; }
  @media  (min-width: 47.9375em) {
    .p02_photo_row_wrapper-is-lightbox .p02_photo_nav-left {
      width: 65px; } }
  .p02_photo_row_wrapper-is-lightbox .p02_photo_nav-left:after {
    opacity: 0.4;
    right: 9px; }
    @media  (min-width: 47.9375em) {
      .p02_photo_row_wrapper-is-lightbox .p02_photo_nav-left:after {
        right: 2px; } }
  .p02_photo_row_wrapper-is-lightbox .p02_photo_nav-left:before {
    background: transparent; }
  .p02_photo_row_wrapper-is-lightbox .p02_photo_nav-left:hover:after {
    opacity: 0.8; }

.p02_nav {
  background: rgba(28, 28, 28, 0.6);
  display: inline-block;
  border: 1px solid #333333;
  position: absolute;
  z-index: 30;
  top: 4px;
  left: 5px;
  max-height: 30px;
  overflow: hidden;
  max-width: 252px; }
  .p02_photo_row_wrapper-is-lightbox .p02_nav {
    top: 10px;
    left: 10px; }
  @media  (min-width: 30em) {
    .p02_nav {
      top: 14px;
      left: 15px; }
      .p02_nav:before {
        font-family: 'ritz-icons';
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e633";
        color: white;
        opacity: 0.4;
        position: absolute;
        left: 8px;
        top: 4px;
        font-size: 19px; } }
  @media  (min-width: 47.9375em) {
    .p02_nav {
      max-width: none; } }
  /* @media  (min-width: 79.5em) {
    .p02_nav {
      top: 15px;
      left: 18px; } } */
  .p02_nav.p02_nav-is-open {
    max-height: 220px;
    max-width: none;
    transition: max-height 0.3s; }
    .p02_nav.p02_nav-is-open:after {
      content: "\e629"; }
  .p02_nav:after {
    font-family: 'ritz-icons';
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e62a";
    color: white;
    opacity: 0.4;
    position: absolute;
    right: 7px;
    top: 8px;
    font-size: 14px; }
  .p02_nav:hover:before, .p02_nav:hover:after {
    opacity: 0.8; }

a.p02_nav_item {
  display: none;
  line-height: 1em;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  padding: 10px 86px 3px 10px;
  opacity: 0.5; }
  @media  (min-width: 30em) {
    a.p02_nav_item {
      padding-left: 36px; } }
  a.p02_nav_item.p02_nav_item-is-current {
    display: block;
    opacity: 1;
    position: relative; }
  a.p02_nav_item:hover {
    opacity: 1; }
  .p02_nav-is-open a.p02_nav_item {
    display: block; }

.p02_nav_item-label {
  display: none; }

.p02_nav_utilities-count {
  font-size: 14px;
  color: white;
  text-align: center;
  position: absolute;
  right: 27px;
  top: 10px; }

.p02_icon_link {
  display: block;
  position: absolute;
  z-index: 30;
  height: 25px;
  padding: 7px 14px 9px;
  width: auto; }
  .p02_icon_link [class*="icon-"] {
    position: absolute; }
  .p02_icon_link:hover {
    text-decoration: none; }
    .p02_icon_link:hover [class*="icon-"] {
      opacity: 0.8; }

.p02_icon_link-caption {
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid #333333;
  bottom: 14px;
  left: 5px;
  color: #1c1c1c;
  text-decoration: none !important;
  text-transform: uppercase;
  display: none; }
  .p02_icon_link-caption:hover, .p02_icon_link-caption:active, .p02_icon_link-caption:hover:before, .p02_icon_link-caption:active:before {
    text-decoration: none !important; }
  .p02_icon_link-caption:before {
    content: 'Caption';
    text-decoration: none !important; }
  .p02_photo-caption-is-active .p02_icon_link-caption:before {
    content: 'Hide'; }
  .p02_icon_link-caption [class*="icon-"] {
    opacity: 1;
    left: 4px;
    top: 4px;
    font-size: 19px; }
  .p02_icon_link-caption:hover {
    background: rgba(255, 255, 255, 0.9); }
    .p02_icon_link-caption:hover [class*="icon-"] {
      opacity: 0.8; }
  .p02_photo-caption-is-active .p02_icon_link-caption {
    background: rgba(28, 28, 28, 0.6);
    color: white; }
    .p02_photo-caption-is-active .p02_icon_link-caption [class*="icon-"] {
      opacity: 1; }
    .p02_photo-caption-is-active .p02_icon_link-caption:hover [class*="icon-"] {
      opacity: 0.8; }
  .p02_photo_row_wrapper-is-lightbox .p02_icon_link-caption {
    bottom: 18px;
    left: 10px; }
  @media  (min-width: 30em) {
    .p02_icon_link-caption {
      bottom: 16px;
      left: 15px; } }
  @media  (min-width: 47.9375em) {
    .p02_icon_link-caption {
      display: block !important;
      background: rgba(28, 28, 28, 0.6);
      color: white;
      bottom: 18px; }
      .p02_icon_link-caption [class*="icon-"] {
        opacity: 1; }
      .p02_icon_link-caption:hover {
        background: rgba(28, 28, 28, 0.6); }
        .p02_icon_link-caption:hover [class*="icon-"] {
          opacity: 0.8; }
      .p02_photo-caption-is-active .p02_icon_link-caption {
        background: rgba(255, 255, 255, 0.6);
        color: #1c1c1c; }
        .p02_photo-caption-is-active .p02_icon_link-caption [class*="icon-"] {
          opacity: 1; }
        .p02_photo-caption-is-active .p02_icon_link-caption:hover {
          background: rgba(255, 255, 255, 0.9); }
          .p02_photo-caption-is-active .p02_icon_link-caption:hover [class*="icon-"] {
            opacity: 0.8; } }
  /* @media  (min-width: 79.5em) {
    .p02_icon_link-caption {
      left: 18px; } } */

.p02_icon_link-expand {
  display: none;
  background-color: white;
  border-radius: 3px;
  border-radius: 3px;
  border: 1px solid transparent;
  top: 5px;
  right: 5px;
  color: #1c1c1c;
  opacity: 0.4; }
  .p02_icon_link-expand [class*="icon-"] {
    left: 6px;
    top: 6px;
    font-size: 16px; }
  @media  (min-width: 30em) {
    .p02_icon_link-expand {
      top: 11px;
      right: 14px; } }
  .p02_icon_link-expand:hover {
    opacity: 0.8; }
  .p02_photo_row_wrapper-is-lightbox .p02_icon_link-expand {
    display: none; }

a.p02_lightbox-close {
  line-height: 1em;
  color: #1c1c1c;
  opacity: 0.4;
  text-transform: uppercase;
  text-decoration: none;
  border: 1px solid transparent;
  position: absolute;
  z-index: 50;
  top: 10px;
  right: 10px;
  display: none;
  background-color: white;
  border-radius: 3px;
  border-radius: 3px;
  height: 30px;
  width: 30px;
  overflow: hidden; }
  @media  (min-width: 30em) {
    a.p02_lightbox-close {
      padding: 10px 26px 3px 10px;
      width: auto; } }
  .p02_photo_row_wrapper-is-lightbox a.p02_lightbox-close {
    display: inline-block; }
  a.p02_lightbox-close:hover {
    opacity: 0.8;
    text-decoration: none; }
  a.p02_lightbox-close:after {
    font-family: 'ritz-icons';
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e605";
    position: absolute;
    right: 2px;
    top: 3px;
    font-size: 24px;
    border-radius: 3px; }
    @media  (min-width: 30em) {
      a.p02_lightbox-close:after {
        right: 5px;
        top: 5px;
        font-size: 20px; } }

.p02_lightbox-close-label {
  display: none; }
  @media  (min-width: 30em) {
    .p02_lightbox-close-label {
      display: inline; } }

.p02_thumbnail_wrapper {
  display: none;
  padding: 5px 0; }
  .p02_thumbnail_wrapper:after {
    content: '';
    background: linear-gradient(to left, #ffffff 10%, rgba(255, 255, 255, 0) 100%);
    width: 50px;
    height: 100%;
    display: block;
    position: absolute;
    right: 0;
    top: 0; }
  .p02_photo_row_wrapper-is-lightbox .p02_thumbnail_wrapper {
    display: block;
    position: fixed;
    bottom: 0;
    background: white;
    z-index: 300;
    width: 100%; }

.p02_thumbnail_mask {
  overflow-y: hidden;
  overflow-x: auto;
  position: relative;
  height: 90px; }

.p02_thumbnail_row {
  display: table;
  margin-right: auto;
  margin-left: auto;
  padding-right: 60px;
  padding-left: 60px; }

.p02_thumbnail_cell {
  display: table-cell;
  padding: 0 5px; }
  .p02_thumbnail_cell:first-child {
    padding-left: 10px; }

.p02_thumbnail_item {
  display: block;
  height: 60px;
  width: 60px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .p02_thumbnail_item.p02_thumbnail_item-is-current {
    border: 2px solid #1c1c1c;
    position: relative; }
    .p02_thumbnail_item.p02_thumbnail_item-is-current:hover {
      cursor: default; }
    .p02_thumbnail_item.p02_thumbnail_item-is-current:after {
      content: '';
      display: block;
      height: 60px;
      width: 60px;
      position: absolute;
      border: 2px solid #1c1c1c;
      background-color: #1c1c1c;
      top: -2px;
      right: -2px;
      opacity: 0.5; }

/* overwritten css */
.content-wrapper {
    overflow-x: hidden;
    overflow-y: hidden;
}
@media (min-width: 47.9375em){

.p02_A {
    margin-top: 0;
}
}
/* @media (min-width: 79.5em) {

  .p02_photo {
      width: 33%;
  }

}
.p02_photo-is-right {
  left: 33%;
}
.p02_photo-is-righter {
  left: 66%;
}
.p02_photo {
  opacity: 1;
}
.p02_A {
  margin-top: 0;
}
.p02_photo-is-active {
  background-color: transparent;
} */
/* @media (min-width: 79.5em)
{
  .p02_image_wrapper {
      height: 430px;
      width: auto;
  }
  .p02_photo {
      height: 430px;
  }
  .p02_photo_row {
    height: 430px;
    text-align: left;
  }
}
.p02_photo  {cursor: pointer;}
.p02_image_wrapper h3 {
  letter-spacing: .05em;
  font-family: "Proxima Nova", Helvetica, "Arial Unicode", sans-serif;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
}
.p02_image_wrapper .feature-block__body ,.p02_image_wrapper .feature-block__cta-wrapper {
  text-align: left;
}
.p02_photo_row_wrapper {
  margin-top: 30px;
} */
