.p04_A, .p04_B, .p04_C, .p04_D, .p04_E {
  margin-bottom: 60px;
  background: #1c1c1c; }
  @media  (max-width: 42em) {
    .p04_A, .p04_B, .p04_C, .p04_D, .p04_E {
      margin-bottom: 45px; } }
  .n10_A + .l-container-wrapper .p04_A, .n10_A + .p04_A, .n10_A + .l-container-wrapper .p04_B, .n10_A + .p04_B, .n10_A + .l-container-wrapper .p04_C, .n10_A + .p04_C, .n10_A + .l-container-wrapper .p04_D, .n10_A + .p04_D, .n10_A + .l-container-wrapper .p04_E, .n10_A + .p04_E {
    margin-top: -60px; }
    @media  (max-width: 42em) {
      .n10_A + .l-container-wrapper .p04_A, .n10_A + .p04_A, .n10_A + .l-container-wrapper .p04_B, .n10_A + .p04_B, .n10_A + .l-container-wrapper .p04_C, .n10_A + .p04_C, .n10_A + .l-container-wrapper .p04_D, .n10_A + .p04_D, .n10_A + .l-container-wrapper .p04_E, .n10_A + .p04_E {
        margin-top: -45px; } }

.p04_frame {
  text-align: center;
  padding: 0;
  margin: 0;
  position: relative;
  max-height: 465px;
  overflow: hidden; }
  .p04_frame:before {
    content: '';
    display: block;
    padding-top: 33.3333333333%; }
  .p04_D .p04_frame, .p04_E .p04_frame {
    max-height: 788px; }
    .p04_D .p04_frame:before, .p04_E .p04_frame:before {
      padding-top: 56.25%; }
  .p04_A .p04_frame:before {
    padding-top: 20%; }

.p04_slide_list {
  list-style: none;
  margin: 0 auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }
  .p04_A .p04_slide_list {
    max-width: none; }

.p04_slide {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  line-height: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 1s ease;
  opacity: 1;
  max-height: 465px;
  overflow: hidden; }
  .carousel-active .p04_slide {
    opacity: 0; }
  .p04_D .p04_slide, .p04_E .p04_slide {
    max-height: 788px; }
  .p04_slide:first-child {
    z-index: 1; }
  .p04_slide.is-previous {
    z-index: 40;
    opacity: 0; }
  .p04_slide.is-active {
    z-index: 30;
    opacity: 1; }

.p04_img {
  width: 100%; }
  .p04_B .p04_img, .p04_C .p04_img, .p04_D .p04_img, .p04_E .p04_img {
    max-width: 1400px; }

.p04_caption-outer {
  transition: opacity 1s ease;
  width: 100%;
  height: 60px;
  position: absolute;
  background: rgba(28, 28, 28, 0.4);
  opacity: 1;
  bottom: 0; }
  .caption-hidden .p04_caption-outer {
    opacity: 0; }

.p04_caption {
  display: table;
  color: white;
  font-size: 12px;
  line-height: 1em;
  text-align: left;
  padding-left: 95px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  height: 60px; }
  @media  (min-width: 30em) {
    .p04_caption {
      font-size: initial; } }

.p04_caption-cell {
  display: table-cell;
  vertical-align: middle;
  padding: 20px 55px 12px 10px; }
  @media  (min-width: 30em) {
    .p04_caption-cell {
      padding-left: 15px; } }

.p04_utilities {
  position: absolute;
  bottom: 0;
  z-index: 50;
  width: 100%;
  margin: 0;
  display: block; }

.p04_utilities-inner {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  position: relative; }

.p04_icon_link-control {
  position: absolute;
  right: 5px;
  bottom: 5px;
  display: none;
  height: 30px;
  width: 30px;
  background: rgba(28, 28, 28, 0.6);
  border: 1px solid #333333;
  color: white; }
  .carousel-active .p04_icon_link-control {
    display: block; }
  .p04_icon_link-control [class*="icon-"] {
    position: absolute;
    opacity: 1;
    left: 4px;
    top: 4px;
    font-size: 19px; }
  .p04_icon_link-control:hover {
    background: rgba(28, 28, 28, 0.6);
    text-decoration: none; }
    .p04_icon_link-control:hover [class*="icon-"] {
      opacity: 0.8; }
  @media  (min-width: 30em) {
    .p04_icon_link-control {
      bottom: 15px;
      right: 15px; } }
  .p04_icon_link-control[data-state="paused"] {
    background: rgba(255, 255, 255, 0.6);
    color: #1c1c1c; }
    .p04_icon_link-control[data-state="paused"] [class*="icon-"] {
      opacity: 1;
      left: 3px; }
      .p04_icon_link-control[data-state="paused"] [class*="icon-"]:before {
        content: "\e606"; }
    .p04_icon_link-control[data-state="paused"]:hover {
      background: rgba(255, 255, 255, 0.9); }
      .p04_icon_link-control[data-state="paused"]:hover [class*="icon-"] {
        opacity: 0.8; }

.p04_caption_toggle {
  transition: opacity 1s ease;
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid #333333;
  bottom: 18px;
  left: 18px;
  position: absolute;
  color: #1c1c1c;
  text-decoration: none !important;
  text-transform: uppercase;
  height: 25px;
  padding: 7px 14px 9px;
  line-height: 1em;
  opacity: 0; }
  .current-slide-has-caption .p04_caption_toggle {
    opacity: 1; }
  .p04_caption_toggle:hover, .p04_caption_toggle:active, .p04_caption_toggle:hover:before, .p04_caption_toggle:active:before {
    text-decoration: none !important; }
  .p04_caption_toggle:hover {
    background: rgba(255, 255, 255, 0.9); }
  .p04_caption_toggle:before {
    content: 'Hide';
    text-decoration: none !important; }
  .caption-hidden .p04_caption_toggle {
    background: rgba(28, 28, 28, 0.6);
    color: white; }
    .caption-hidden .p04_caption_toggle:before {
      content: 'Caption'; }

@media  (min-width: 47.9375em) {
  .p04_A .p04_frame:before {
    padding-top: 14.2857142857%; } }

@media  (min-width: 79.5em) {
  .p04_img.full-bleed {
    max-width: none; }
  .p04_img.lock-middle {
    position: absolute;
    top: -50%;
    left: -25%;
    min-height: 150%;
    min-width: 150%;
    max-width: none; }
  .p04_img.lock-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: none; } }
/*# sourceMappingURL=p04.brand-hero.css.map */
.carousel-dots li.active {
  background: #fff;
  transform: scale(2);
}
