.bannerImageOuter {
    max-height: 465px;
    overflow: hidden;
}

select.tab-select.h-ML option {
    cursor: pointer;
}

#rcyc-page-header .brand-nav li:last-child a {
    display: inline !important;
}

.mainRowSection {
    width: 100%;
    float: left;
}

.bannerImageOuter img {
    width: 100%;
}

.bodyContentOuter a.linkColor {
    color: #3b68b3;
}

.bodyContentOuter .extra_fee {
    color: #757575;
    font-size: 10px;
}

.textCenter {
    text-align: center;
}

.bodyContentOuter a.secondary-cta {
    color: #3b68b3;
    text-decoration: none;
}

.bodyContentOuter a.secondary-cta:hover {
    text-decoration: underline;
}

input[type=checkbox] {
    border: 1px solid #bfbfbf;
}

input[type=checkbox]:focus {
    opacity: 1;
}

#rcyc-page-footer .g04 {
    margin-top: 0px;
}

.appComponentBody {
    margin-bottom: 64px;
}

form label {
    color: #1c1c1c;
    padding-bottom: 3px;
}

form input[type="text"].basic-form_input {
    text-decoration: none;
}

form input[type="email"].basic-form_input {
    text-decoration: none;
}

form select.basic-form_input {
    text-decoration: none;
}

form textarea.basic-form_input {
    color: #3e3e3e;
    resize: none;
    text-decoration: none;
}

form input::placeholder {
    color: #6f6f6f;
}

form input:-ms-input-placeholder {
    color: #6f6f6f;
}

form input::-ms-input-placeholder {
    color: #6f6f6f;
}

input[type="radio"]:focus {
    background: transparent;
    border: 1px solid #1c1c1c;
}


/*
.swal2-show {
	height: 100%;
  overflow-y: scroll;
}

.swal2-content {
	height: 90%;
}
*/

div.reqCallModalSwtOuter {
    /*overflow-y:scroll;*/
    /*width:950px!important;*/
    width: 82%;
    height: 95%;
}

div.reqCallModalSwtOuter .swal2-content {
    height: 90%;
    overflow-y: Scroll;
    color: #1c1c1c;
    padding: 0.25em;
}

div.swal2-popup {
    padding: 0;
}

.reqCallModalSwtOuter .swal2-content::-webkit-scrollbar {
    width: .4em;
}

.reqCallModalSwtOuter .swal2-content::-webkit-scrollbar,
.reqCallModalSwtOuter .swal2-content::-webkit-scrollbar-thumb {
    overflow: visible;
    border-radius: 4px;
}

.reqCallModalSwtOuter .swal2-content::-webkit-scrollbar-thumb {
    visibility: hidden;
}

.reqCallModalSwtOuter:hover .swal2-content::-webkit-scrollbar-thumb {
    visibility: visible;
    background: rgba(0, 0, 0, .2);
}

.reqCallModalSwtOuter:hover .swal2-content::-webkit-scrollbar-track-piece {
    background: #fff;
}


/*code to visible/hide scrollbar*/

.reqCallModalSwtOuter .scrollbarToggle {
    position: absolute;
    background: white;
    height: 100%;
    top: 0;
    right: 0;
    width: 1%;
    -webkit-transition: all .5s;
    opacity: 1;
    z-index: 1;
}

.reqCallModalSwtOuter .swal2-modal {
    position: relative;
}

.reqCallModalSwtOuter:hover .scrollbarToggle {
    opacity: 0;
    z-index: -1;
    -webkit-transition: all .5s;
}

#rcyc-passport-page .updateInfo p {
    letter-spacing: 0em;
}


/*div.swal2-show{
  animation: swal2-show 1s !important;

}*/

div.swal2-show {
    -webkit-animation: fadein 1.3s;
    animation: fadein 1.3s;
    -webkit-animation: zoomin 0.5s ease-in;
    -moz-animation: zoomin 0.5s ease-in;
    -ms-animation: zoomin 0.5s ease-in;
    -o-animation: zoomin 0.5s ease-in;
    animation: zoomin 0.5s ease-in;
}

div.swal2-hide {
    -webkit-animation: zoomin 0.5s ease-out;
    animation: zoomout 0.5s ease-in forwards;
}

.cursorPointer {
    cursor: pointer;
}

@-webkit-keyframes zoomout {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

.modalContainer {
    color: black;
}

@-webkit-keyframes zoomin {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes zoomin {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}


/* Firefox < 16 */

@-moz-keyframes zoomin {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}


/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes zoomin {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}


/* Internet Explorer */

@-ms-keyframes zoomin {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}


/* Opera < 12.1 */

@-o-keyframes zoomin {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}


/*
@-webkit-keyframes zoomout {
  1% {transform: scale(0);}
  100% {transform: scale(0);}
}
*/


/* Firefox < 16 */

@-moz-keyframes zoomout {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(0);
    }
}


/* Internet Explorer */

@-ms-keyframes zoomout {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1);
    }
}


/* Opera < 12.1 */

@-o-keyframes zoomout {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}


/*
.reqCallModalSwtOuter button.swal2-close {
  right:17px;
}
*/

.input-form-element,
textarea,
[type="text"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="search"],
[type="password"],
[type="tel"],
[type="time"],
[type="url"],
[type="week"],
select.basic-form_select {
    margin-bottom: 4px;
}

form span.error,
#formSubmitErrorId span.error {
    padding: 0px;
}

form .basic-form_label {
    margin-top: 14px;
}


/*form .basic-form_group:first-child .basic-form_label {
  margin-top: 0px;
}*/

form .formFirstRow:first-child .basic-form_label {
    margin-top: 0px;
}

form .basic-form_group {
    margin-bottom: 0px;
}

.pcfiText {
    text-transform: capitalize !important;
    margin-top: 10px !important;
}

.bodyContentOuter .secondary-cta:focus {
    text-decoration: underline !important;
}

.checkBoxText {
    letter-spacing: .5px;
    font-size: 14px;
    padding: 10px 5px;
}

#guestLoginSwalId .guestLoginSwtOuter {
    width: auto !important;
    max-width: 800px;
    margin-bottom: 15px;
}

#guestLoginSwalId .swal2-header {
    min-height: 50px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 0px 25px;
}


/*.guestLoginSwtOuter {
  top: 55px;
}*/

#guestLoginSwalId .swal2-close {
    color: #1c1c1c;
    font-size: 30px;
    font-weight: 600;
    background: #fff;
}

input:read-only {
    cursor: default;
}

input:read-only:focus,
input:read-only:hover {
    border: 1px solid #bfbfbf;
}

textarea::-webkit-input-placeholder {
    color: #6f6f6f;
}

textarea::-moz-placeholder {
    color: #6f6f6f;
}

textarea:-ms-input-placeholder {
    color: #6f6f6f;
}

textarea:-moz-placeholder {
    color: #6f6f6f;
}

.formLoader {
    width: 50px;
}

.pointerEventsNone {
    pointer-events: none;
}

.readOnly,
select:disabled {
    background: #bfbfbf !important;
    pointer-events: none;
    opacity: 0.5;
    color: #4B4B4B !important;
}

.btnLoaderOuter {
    position: relative;
}

.btnLoaderOuter .btnLoader {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1000;
    width: 100%;
    text-align: center;
    background: #6f6f6f;
    height: 39px;
    top: 1px;
}

.btnLoaderOuter .btnLoader img {
    width: 37px;
}

.alert {
    letter-spacing: .5px;
}

.checkmark {
    border: 0px solid #e1e1e1;
}

.checkmark {
    position: absolute;
    top: 1px;
    left: 5px;
    height: 17px;
    width: 18px;
    border-radius: 50%;
    border: 1px solid #e1e1e1;
}

input[type="radio"]:focus~.checkmark {
    border: 1px solid #1c1c1c;
}

.errorSectionOuter {
    min-height: 60px;
}

.phoneOuter {
    position: relative;
    display: inline-block;
}

.dialCodeOuter {
    position: absolute;
    top: 44px;
    left: 8px;
}

.phone_input {
    padding-left: 20px;
}


/* .grecaptcha-badge {
	display: none;
} */

.alert-error p,
.error p,
.errorSection p,
.alert-error,
.error,
.errorSection {
    color: #ce4844;
    margin-bottom: 0px;
}

.success {
    color: #468847;
}

#guestPreContractSwalId .swal2-close {
    z-index: 999;
    color: #ce4844;
}

#guestPreContractSwalId .swal2-popup {
    width: 50em;
}

.icon-arrow-up,
.icon-arrow-down {
    cursor: pointer;
}
.swal2-close
{
    z-index: 999;
}
#reservationSwalId .swal2-close,
#diningSwalId .swal2-close {
    z-index: 999;
    font-size: 58px;
    color: #6f6f6f !important
}
.swalCancel
{
  cursor: pointer;
}

.textRight {
    text-align: right;
}

.moreInfo {
    cursor: pointer;
}

input[type="submit"]:disabled {
    background: rgba(28, 28, 28, 0.26);
    cursor: default;
}

li.tab .tab-link {
    background: #bfbfbf;
    position: relative;
    margin-bottom: 0px;
    height: 41px;
}

li.tab {
    margin-right: -7px;
}

li.tab .tab-link.is-active {
    background: white;
}

li.tab .tab-link:focus {
    text-decoration: underline;
}

.meters-image {
    width: 52px;
}

.fullLoaderOuter {
    width: 100%;
    display: inline-block;
    text-align: center;
}
a.rcyc-aria-a {
  color: #1c1c1c;
  text-decoration: none;
}
a.rcyc-aria-a:hover,
a.rcyc-aria-a:focus,
a.rcyc-aria-a:active,
a.rcyc-aria-a:visited {
  color: #1c1c1c;
  text-decoration: none !important;
  cursor: text;
}
.letterSpacing {
  letter-spacing: .5px;
}
.errorptag {
	margin-bottom:0px;
	display: block;
}

  .letterSpacing {
    letter-spacing: .5px;
  }
  /* .phoneFlagContainer .countrySearchListOuter li:nth-child(2) {
      border-bottom: 1px solid #bfbfbf;
  } */
  .errorptag {
      margin-bottom:0px;
      display: block;
  }
  
.fullLoaderOuter .fullLoader {
    /* height: 39px;
  top: 1px;
  margin:0 auto;
  width: 200px; */
    width: 60px;
}
#spaSwalIdtimeExpired .swal2-close
  {
    display: none !important;
  }
  app-rcyc-ngu-carousel {
    min-height: auto !important;
    background: none !important;
  }
.tickedState {
  background-image: url("/assets/components/images/info_ico_tick.png");
}
.normalState {
	background-image: url("/assets/components/images/inactive_ico.png");
}
.closedState {
	background-image: url("/assets/components/images/info_ico_delete.png");
}
.stateTickImage {
	width: 28px;
	height: 28px;
	background-size: contain;
	display: inline-block;
	background-repeat: no-repeat;
}
.pageLoaderOuterDiv {
	text-align: center;
}
.pageLoaderImg {
	width: 60px;
}
.show-menu .content-wrapper {
	-webkit-transform: none !important;
	transform: none !important;
}
.videoModuleSectionOuter {
	position: relative;
}
.videoModuleSectionOuter .play-btn-wrapper {
	position: absolute;
	top: 0;
	width: 100%;
	text-align: center;
	height: 100%;
}
.videoModuleSectionOuter .play-btn-cover {
	display: table;
	width: 100%;
	height: 100%;
}
.videoModuleSectionOuter .play-btn-box {
	display: table-cell;
	vertical-align: middle;
}
.videoModuleSectionOuter .play-btn-box a {
	display: inline-block;
}
.videoModuleSectionOuter .play-btn-box a img{
  margin-bottom: 0;
}
/* ################################################################################################################################################
 *        Media query
 ################################################################################################################################################### */

@media (min-width: 768px) and (max-width: 1199px) {
    #rcyc-page-header .brand-nav__link {
        font-size: 11px!important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    form .basic-form_label {
        min-height: 37px;
        font-size: 12px;
    }
    .basic-form_label-required:after {
        position: relative;
    }
}

@media (min-width: 560px) and (max-width: 766px) {
    .basic-form_label-required:after {
        top: 2px;
    }
}

@media (min-width: 766px) and (max-width: 991px) {
    .basic-form_label-required:after {
        top: 0px;
    }
}
@media only screen and (max-width : 767px) and (min-width: 767px) {
	body:not(.brand-home) {
    padding-top: 0px !important;
	}
}
@media (max-width: 1200px) {
  #guestLoginId .guestLoginSwtOuter {
    width: 70%;
  }
  
}


@media (max-width: 1024px) {
    #guestLoginId .guestLoginSwtOuter {
        width: 90%;
    }
}

@media (max-width: 769px) {
    .appComponentBody {
        min-height: auto !important;
    }
    #guestLoginSwalId .guestLoginSwtOuter {
        width: 90% !important;
		}
		/* #hd_cruise_mobile {
			display: none;
		}
		.show-menu #hd_cruise_mobile {
			display: block;
		} */

		.brand-menu-wrapper {
			height: 100% !important;
		}
		.brand-menu-wrapper .brand-menu {
			height: 100% !important;
		}
		/* .show-menu .brand-menu-wrapper {
			height: calc(100% - 37px);
		}
		.show-menu .brand-menu-wrapper .brand-menu {
			height: calc(100% - 37px);
		} */
}

@media (max-width: 767px) {
    .footer-fat {
        padding: 20px;
        line-height: 1.5;
    }
    form .formFirstRow:first-child .basic-form_label {
        margin-top: 14px;
    }
}
@media (max-width: 766px) {
  #guestLoginId .guestLoginSwtOuter {
    width: 60%;
  }
}
@media (max-width: 766px) {
    #guestLoginId .guestLoginSwtOuter {
        width: 60%;
    }
    .appComponentBody {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width:600px) {
    .brand-header-mobile {
        z-index: 999;
    }
}

@media screen and (max-width: 560px) {
    .basic-form_label-required:after {
        top: 0px;
    }
    #guestLoginId .guestLoginSwtOuter {
        width: 70%;
    }
    #guestLoginSwalId .guestLoginSwtOuter {
        width: 85% !important;
    }
}
@media only screen and (max-width:420px){
  #guestLoginSwalId .guestLoginSwtOuter {
    width: 90% !important;
  }
}
