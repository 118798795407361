
.phoneFlagContainer {
	position: relative;
  }
  /*.phoneFlagContainer .phoneFlagOuter:focus {*/
  /*	border: 1px solid #2f538e;*/
  /*}*/
  .phoneFlagContainer .eachPhoneCountryName:focus {
	background-color: #358ffb;
  }
  .phoneFlagContainer .phoneFlagOuter {
	  width: 70px!important;
	  padding: 7px;
	  border: 1px solid #bfbfbf;
	  margin-right: 0!important;
	height: 32px;
	border-right: 0px;
	position: relative;
	display: flex;
	align-items: center;
  }
  .phoneFlagContainer .phoneNumberInputOuter {
	  width: calc(100% - 70px)!important;
  }
  .phoneFlagContainer .phoneFlagOuter .countryCodeOuter:after {
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid rgb(136, 136, 136);
	content: '';
	position: absolute;
	display: inline-block;
	right: 7px;
	top: 13px;
  }
  .phoneFlagContainer .countrySearchListOuter {
	  max-height: 270px;
	  margin: 5px 4px 4px 0px;
	  overflow-y: scroll;
	  letter-spacing: normal;
  }
  .phoneFlagContainer .countryResultOuter {
	position: absolute;
	background: #fff;
	width: 100%;
	z-index: 1;
	margin-top: 47px;
  }
  .phoneFlagContainer .countryResultInner {
	  border: 1px solid #bfbfbf;
	  border-top: none;
  }
  .phoneFlagContainer .countrySearchListOuter {
	list-style: none;
	margin-right: 0px;
	margin-top: 0px;
  }
  .phoneFlagContainer .countrySearchListOuter li {
	padding: 5px 0px;
	margin-left: 0px;
	padding-left: 20px;
	margin-bottom: 0px;
  }
  .phoneFlagContainer .countrySearchListOuter li:hover {
	background-color: #358ffb;
	color: #fff;
	cursor: pointer;
  }
  
  .phoneFlagContainer .phoneNumberInput {
	margin-bottom: 0px;
  }
  .phoneFlagContainer .flagImg {
	width: 20px;
	height: 16px;
	float: left;
	background: #e9e9e9;
	display: none;
	visibility: hidden;
  }
  .phoneFlagContainer .countryCodeOuter {
	  letter-spacing: normal;
	  padding-left: 2px;
	cursor: pointer;
	font-size: 14px;
  }
  .phoneFlagContainer .countrySearchOuter {
	  position: relative;
	  z-index: 1010;
	  white-space: nowrap;
	  margin: 0px;
	  padding: 3px 4px;
	  /* width: 100%;  */
  }
  .phoneFlagContainer .countrySearchOuter input {
	  height: auto;
	  font-size: 1em;
	  font-family: sans-serif;
	  line-height: normal !important;
	  margin: 1px 0px;
	  padding: 4px 20px;
	  outline: 0px;
	  border-width: 1px;
	  border-style: solid;
	  border-color: rgb(170, 170, 170);
	  border-image: initial;
	  background: url(/assets/components/images/chosen-sprite.png) -30px -20px no-repeat;
	  border-radius: 0px;
	  border: none;
  }
  .phoneFlagContainer .phoneNoError {
	width: 100%;
  }
  .phoneFlagContainer .countrySearchListOuter li:nth-child(2) {
	  border-bottom: 1px solid #bfbfbf;
  }
  .phoneFlagContainer input[type="text"] {
	width: 100%;
  }
  .phoneFlagContainer .noResultsFound {
	padding: 5px 0px;
	padding-left: 20px;
  }
  .phoneFlagContainer .highlightText:first-child {
	text-decoration: underline;
  }
  .phoneFlagContainer {
	  position: relative;
  }
  .phoneFlagContainer .phoneFlagOuter {
	  width: 25.42%;
	  padding: 7px;
	  margin-right: 0;
	  height: 32px;
	  border-right: 0;
	  position: relative;
	border: none;
	border-bottom: 1px solid #1c1c1c;
	min-height: 47px;
  }
  .phoneFlagOuter:hover {
	  color: #3B68B3;
	  cursor: pointer;
  }
  .phoneFlagContainer .phoneFlagOuter .countryCodeOuter:after {
	  width: 0;
	  height: 0;
	  border-left: 5px solid transparent;
	  border-right: 5px solid transparent;
	  border-top: 5px solid rgb(136, 136, 136);
	  content: '';
	  position: absolute;
	  display: inline-block;
	  right: 7px;
	  top: 16px;
  }
  .phoneFlagContainer .countrySearchListOuter {
	  max-height: 270px;
	  margin: 5px 4px 4px 0px;
	  overflow-y: scroll;
	  letter-spacing: normal;
  }
  .phoneFlagContainer .countryResultOuter {
	  position: absolute;
	  background: #fff;
	  width: 100%;
	  z-index: 1;
  }
  .phoneFlagContainer .countryResultInner {
	  border: 1px solid #bfbfbf;
	  border-top: none;
  }
  .phoneFlagContainer .countrySearchListOuter {
	  list-style: none;
	  margin-right: 0px;
	  margin-top: 0px;
  }
  .phoneFlagContainer .countrySearchListOuter li {
	  padding: 5px 0px;
	  margin-left: 0px;
	  padding-left: 20px;
	  margin-bottom: 0px;
  }
  .phoneFlagContainer .countrySearchListOuter li:hover {
	  background-color: #358ffb;
	  color: #fff;
	  cursor: pointer;
  }
  .phoneFlagContainer .phoneNumberInput {
	  margin-bottom: 0px;
  }
  .phoneFlagContainer .flagImg {
	  width: 25px;
	  height: 25px;
	  float: left;
	  background: transparent;
	  margin-top: -5px;
	  display: none;
	  visibility: hidden;
  }
  .phoneFlagContainer .countryCodeOuter {
	  letter-spacing: normal;
	  padding-left: 2px;
	  cursor: pointer;
	  font-size: 16px;
  }
  .phoneFlagContainer .countrySearchOuter {
	  position: relative;
	  z-index: 1010;
	  white-space: nowrap;
	  margin: 0px;
	  padding: 3px 4px;
	  /* width: 100%;  */
  }
  .phoneFlagContainer .countrySearchOuter input {
	  height: auto;
	  font-size: 1em;
	  font-family: sans-serif;
	  line-height: normal;
	  margin: 1px 0px;
	  padding: 4px 20px;
	  outline: 0px;
	  border-width: 1px;
	  border-style: solid;
	  border-color: rgb(170, 170, 170);
	  border-image: initial;
	  background: url(/assets/components/images/chosen-sprite.png) -30px -20px no-repeat;
	  border-radius: 0px;
	  border: none;
  }
  .phoneFlagContainer .phoneNoError {
	  width: 100%;
  }
  .phoneFlagContainer .errorptag {
	  position: relative;
  }
  .phoneFlagContainer input[type="text"] {
	  width: 100%;
  }
  .phoneFlagContainer .noResultsFound {
	  padding: 5px 0px;
	  padding-left: 20px;
  }
  .phoneFlagContainer .highlightText:first-child {
	  text-decoration: underline;
  }
  
  /* ########################################################################
   *        Media query
   ########################################################################## */
  
  @media (max-width: 1200px) {
	.phoneFlagContainer .phoneFlagOuter {
	  padding-left: 5px;
	}
  }
  @media (max-width: 1100px) {
	.phoneFlagContainer .phoneFlagOuter {
	  padding-left: 3px;
	}
  }
  
  @media (max-width: 991px) {
	.phoneFlagContainer .phoneFlagOuter {
	  width: 34.42%;
	}
	.phoneFlagContainer .phoneNumberInputOuter {
	  width: 65.56%;
	}
  }
  @media (max-width: 766px) {
	.phoneFlagContainer .phoneFlagOuter {
	  width: 24.42%;
	  padding-left: 10px;
	}
	.phoneFlagContainer .phoneNumberInputOuter {
	  width: 75.56%;
	}
  }
  @media only screen and (max-width:480px){
	.phoneFlagContainer .phoneFlagOuter {
	  width: 29.42%;
	  padding-left: 10px;
	}
	.phoneFlagContainer .phoneNumberInputOuter {
	  width: 70.56%;
	}
  }
  @media only screen and (max-width:420px){
	.phoneFlagContainer .phoneFlagOuter {
	  padding-left: 4px;
	}
  }