.header {
  padding-top: 1px;
  position: relative; }

body.is-menu-fixed, body.brand-secondary {
  padding-top: 46px; }
  @media  (min-width: 47.9375em) {
    body.is-menu-fixed, body.brand-secondary {
      padding-top: 63px; } }

.brand-nav-header__wrapper {
  position: relative;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%); }
  .brand-nav-header__wrapper--reverse {
    background: linear-gradient(to bottom, rgba(28, 28, 28, 0.4) 0%, rgba(28, 28, 28, 0) 100%); }
  .brand-nav-header__wrapper--home {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 101;
    transition-property: background, border-color;
    transition-duration: 0.3s; }
    .brand-nav-header__wrapper--home.is-opaque {
      background: white;
      box-shadow: 0px 1px 5px rgba(28, 28, 28, 0.35); }
      .show-takeover .brand-nav-header__wrapper--home.is-opaque {
        background: transparent; }
    .show-takeover .brand-nav-header__wrapper--home {
      position: static;
      background: none; }
      @media  (min-width: 47.9375em) and (min-height: 800px) {
        .show-takeover .brand-nav-header__wrapper--home {
          position: fixed; } }

.brand-header {
  min-height: 45px; }
  .brand-header.brand-header--secondary {
    position: fixed;
    z-index: 104;
    top: 0;
    width: 100%;
    background: white; }
    @media  (min-width: 47.9375em) {
      .brand-header.brand-header--secondary {
        position: static; } }
  .brand-header__menu-toggle, .brand-header__reservation-toggle, .brand-header__locations-link, .brand-header__rewards-link {
    transition-property: all;
    transition-duration: 0.3s;
    padding: 4px 6px 0 6px;
    border: solid 1px transparent;
    border-radius: 3px;
    text-decoration: none;
    opacity: .8;
    transition: opacity 0.3s;
    color: #1c1c1c; }
    .brand-header--reverse .brand-header__menu-toggle, .brand-header--reverse .brand-header__reservation-toggle, .brand-header--reverse .brand-header__locations-link, .brand-header--reverse .brand-header__rewards-link, .show-takeover .brand-header__menu-toggle, .show-takeover .brand-header__reservation-toggle, .show-takeover .brand-header__locations-link, .show-takeover .brand-header__rewards-link {
      color: white;
      text-shadow: 0px 1px 1px rgba(28, 28, 28, 0.8); }
    .is-opaque .brand-header__menu-toggle, .is-opaque .brand-header__reservation-toggle, .is-opaque .brand-header__locations-link, .is-opaque .brand-header__rewards-link {
      color: #1c1c1c;
      text-shadow: none; }
    .show-menu .brand-header__menu-toggle, .show-menu .brand-header__reservation-toggle, .show-menu .brand-header__locations-link, .show-menu .brand-header__rewards-link {
      text-shadow: none; }
    .brand-header__menu-toggle:hover, .brand-header__reservation-toggle:hover, .brand-header__locations-link:hover, .brand-header__rewards-link:hover, .brand-header__menu-toggle:focus, .brand-header__reservation-toggle:focus, .brand-header__locations-link:focus, .brand-header__rewards-link:focus, .brand-header__menu-toggle.is-expanded, .is-expanded.brand-header__reservation-toggle, .is-expanded.brand-header__locations-link, .is-expanded.brand-header__rewards-link {
      text-decoration: none;
      opacity: 1; }
  .brand-header__reservation-toggle, .brand-header__locations-link, .brand-header__rewards-link {
    right: 8px;
    left: auto;
    /* top: 6px; */
    top: 12px;
    font-size: 20px; }
  .show-menu .brand-header {
    top: 0;
    left: 0;
    width: 100%;
    height: 45px;
    border-bottom: solid 1px #3c3c3c;
    z-index: 104;
    background-color: white; }
    .show-menu .brand-header * {
      color: inherit; }
  .show-menu.is-menu-fixed .brand-header {
    position: fixed; }
  .show-takeover .brand-header {
    z-index: 104; }
  .is-fixed .brand-header {
    position: fixed;
    width: 100%;
    z-index: 104;
    background: white;
    border-bottom: solid 1px #bfbfbf;
    transition: top 0.3s;
    box-shadow: 0px 1px 5px rgba(28, 28, 28, 0.35); }
    @media  (min-width: 47.9375em) {
      .is-fixed .brand-header {
        background: none;
        box-shadow: none; } }
  .show-takeover .is-fixed .brand-header {
    z-index: 102; }
  .is-pushed-down .brand-header {
    top: 0; }
    @media  (min-width: 47.9375em) {
      .is-pushed-down .brand-header {
        position: static; } }
  @media  (min-width: 47.9375em) {
    .brand-home .brand-header {
      margin-top: 54px; } }

.brand-lockup {
  width: 200px;
  position: relative;
  top: 5px;
  line-height: 1;
  transition: opacity 0.3s; }
  @media  (min-width: 47.9375em) {
    .brand-lockup {
      top: 0; } }
  .brand-header--home .brand-lockup {
    top: 10px;
    margin-bottom: 20px;
    transition: opacity 0.3s; }
    @media  (min-width: 47.9375em) {
      .brand-header--home .brand-lockup {
        top: 0; } }
    .show-menu .brand-header--home .brand-lockup {
      opacity: 0; }

.brand-header--reverse .brand-lockup__logo {
  fill: white;
  -webkit-filter: drop-shadow(0px 1px 1px rgba(28, 28, 28, 0.8));
          filter: drop-shadow(0px 1px 1px rgba(28, 28, 28, 0.8)); }
.show-menu .brand-lockup__logo {
  -webkit-filter: none;
          filter: none; }
.is-opaque .brand-lockup__logo {
  fill: #1c1c1c;
  -webkit-filter: none;
          filter: none; }
.show-takeover .brand-lockup__logo {
  fill: white;
  -webkit-filter: none;
          filter: none; }
.is-fixed .brand-lockup__logo {
  display: none; }
  @media  (min-width: 47.9375em) {
    .is-fixed .brand-lockup__logo {
      display: inline; } }
.brand-lockup__logo--fixed-only {
  /* display: none; */
  /*    margin-bottom: 15px;*/
  fill: #1c1c1c; }
  .is-fixed .brand-lockup__logo--fixed-only {
    display: inline; }
    @media  (min-width: 47.9375em) {
      .is-fixed .brand-lockup__logo--fixed-only {
        display: none; } }
/*# sourceMappingURL=g01.brand-header.css.map */