.intro-header {
  margin-bottom: 60px;
  margin-top: 60px; }
  @media  (max-width: 42em) {
    .intro-header {
      margin-bottom: 45px; } }
  @media  (max-width: 42em) {
    .intro-header {
      margin-top: 45px; } }
  * + .intro-header {
    margin-top: 60px; }
    @media  (max-width: 42em) {
      * + .intro-header {
        margin-top: 45px; } }
  .showcase .intro-header:first-child {
    margin-top: 0; }
  .column .intro-header:first-child {
    margin-top: 0; }
  .intro-header.c01_A .headline {
    text-align: center;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: .05em; }
    .intro-header.c01_A .headline:after {
      content: " ";
      display: block;
      width: 80px;
      height: 1px;
      background: #1c1c1c; }
  .intro-header.c01_A .body-copy {
    text-align: center; }
    @media  (min-width: 47.9375em) {
      .intro-header.c01_A .body-copy {
        text-align: center;
        width: 66.1016949153%;
        float: left;
        margin-left: 16.9491525424%;
        margin-right: -100%; } }
  .intro-header.c01_B {
    text-align: left; }
    @media  (min-width: 47.9375em) {
      .intro-header.c01_B {
        margin-right: 40px; } }
    .intro-header.c01_B .headline {
      font-family: inherit;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.4;
      letter-spacing: .05em; }
  .intro-header.c01_C .headline {
    letter-spacing: .05em; }
  .intro-header.c01_C.make-left-aligned {
    text-align: left; }
  .intro-header.c01_D:after {
    content: " ";
    display: block;
    clear: both; }
  @media  (min-width: 47.9375em) {
    .intro-header.c01_D .headline-block {
      width: 50%;
      float: left; } }
  .intro-header.c01_D .headline {
    letter-spacing: .05em;
    font-family: "Big Caslon", Palatino, "Times New Roman", serif;
    line-height: 1.1;
    font-size: 28px; }
    @media  (min-width: 35em) {
      .intro-header.c01_D .headline {
        font-size: 34px; } }
    @media  (min-width: 60em) {
      .intro-header.c01_D .headline {
        font-size: 40px; } }
  .intro-header.c01_D .cta-block .block-primary, .intro-header.c01_D .cta-block .block-secondary {
    display: inline-block;
    vertical-align: top; }
  .intro-header.c01_D .cta-block .block-primary {
    margin-right: 16px; }
  .intro-header.c01_D .cta-block .block-secondary {
    font-size: 14px;
    max-width: 325px;
    line-height: 1.3; }
  .intro-header.c01_D .body-copy {
    float: left; }
    @media  (min-width: 47.9375em) {
      .intro-header.c01_D .body-copy {
        width: 48%;
        margin-left: 2%; } }
  .intro-header.c01_E p {
    max-width: 800px; }
  @media  (min-width: 60em) {
    .intro-header.c01_G {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-flow: column wrap;
      -ms-flex-flow: column wrap;
          flex-flow: column wrap;
      -webkit-flex: 1;
          -ms-flex: 1;
              flex: 1;
      height: 500px; } }
  @media  (min-width: 60em) {
    .intro-header.c01_G .block-headline {
      -webkit-flex: 0 0 25%;
          -ms-flex: 0 0 25%;
              flex: 0 0 25%;
      -webkit-order: 1;
          -ms-flex-order: 1;
              order: 1;
      width: 25%; } }
  @media  (min-width: 60em) {
    .intro-header.c01_G .block-image {
      -webkit-flex: 0 0 100%;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
      -webkit-order: 3;
          -ms-flex-order: 3;
              order: 3;
      overflow: hidden;
      width: 75%; } }
  @media  (min-width: 60em) {
    .intro-header.c01_G .block-description {
      -webkit-flex: 0 0 75%;
          -ms-flex: 0 0 75%;
              flex: 0 0 75%;
      -webkit-order: 2;
          -ms-flex-order: 2;
              order: 2;
      width: 25%; } }
  .intro-header.c01_G .headline {
    letter-spacing: .05em;
    font-family: "Big Caslon", Palatino, "Times New Roman", serif;
    line-height: 1.1;
    font-size: 24px; }
  .intro-header.c01_G .cta-block .primary, .intro-header.c01_G .cta-block .secondary {
    display: inline-block;
    vertical-align: middle; }
  .intro-header.c01_G .cta-block .primary {
    margin-right: 2em; }
  .intro-header.c01_G .cta-block .secondary {
    font-size: 14px;
    max-width: 325px;
    line-height: 1.3; }

@media  (min-width: 47.9375em) {
  .c01_headline-table {
    display: table;
    width: 100%; } }

@media  (min-width: 47.9375em) {
  .c01_headline-table-cell {
    display: table-cell; }
    .c01_headline-table-cell h2 {
      margin-bottom: 0; } }

@media  (min-width: 47.9375em) {
  .c01_headline-table-cell-cta {
    text-align: right; } }
/*# sourceMappingURL=c01.intro-header.css.map */