
    .c19_details {
  margin-bottom: 60px;
  overflow: hidden;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.35);
  background: white;
  padding: 0;
  border: 1px solid #333333; }
  @media  (max-width: 42em) {
    .c19_details {
      margin-bottom: 45px; } }
  .c19_details p {
    font-size: 12px; }
  .c19_details__headline {
    display: block;
    padding: 20px 20px 0;
    border-top: solid 1px transparent;
    border-left: solid 1px transparent;
    border-right: solid 1px transparent;
    text-decoration: none;
    text-transform: uppercase;
    color: #1c1c1c;
    transition: background 0.3s; }
  .c19_details__body-copy {
    padding: 20px 20px 0; }
    .c19_details__body-copy p, .c19_details__body-copy li {
      font-size: 14px; }
    @media  (min-width: 47.9375em) {
      .c19_details__body-copy p, .c19_details__body-copy li {
        font-size: 16px; } }
